export const equipmentColumns = [
  { Header: 'Equipment Name', accessor: 'name' },
  { Header: 'Model', accessor: 'modelNumber' },
  {
    Header: "Category",
    accessor: "categories",
    Cell: ({ value, row }) => {
      // console.log("Row data:", row);
      // console.log("this is final Categories value:", value);

      // if (!Array.isArray(value) || value.length === 0) return "N/A";

      // const categoryNames = value.map(cat => cat.name).join(", ");

      return (
        <div>
          {/* {value.length > 0 ? `${value[0]?.name} ${value.length - 1} +` : "N/A"} */}
          {value}
        </div>
      );
    },
  },




  { Header: 'Sub Category', accessor: 'subCategory' },
  // { Header: 'Region', accessor: 'region' },
  { Header: 'Rental Value', accessor: 'rentalValue', format: 'currency' },
  { Header: 'Discount(%)', accessor: 'discount' },
  { Header: 'Final Value', accessor: 'finalRentalValue', format: 'currency' },
];
