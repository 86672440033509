import React, { useState, useEffect } from 'react';
import './categorynavbar.scss';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { getCategoriesData } from '../../service/categoryService';
import Carousel from '../../carousal/Carousel';

function CategoryNavBar({ selectedCategory, setSelectedCategory, selectedNavCategory }) {
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  // Set selectedCategory based on selectedNavCategory
  useEffect(() => {
    if (categoryData.length > 0 && selectedNavCategory) {
      const matchedCategory = categoryData.find((category) => category.name === selectedNavCategory);
      if (matchedCategory) {
        setSelectedCategory(matchedCategory);
      }
    }
  }, [selectedNavCategory, categoryData, setSelectedCategory]);

  const fetchCategories = async () => {
    try {
      const response = await getCategoriesData();
      setCategoryData(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryClick = (id, name) => {
    setSelectedCategory({ id, name });
  };

  return (
    <div className="category-navbar">
      <nav>
        <ul>
          <li
            className={selectedCategory === 'All' ? 'active-link' : ''}
            onClick={() => handleCategoryClick('All', 'All')}
          >
            All
          </li>
          {loading ? (
            Array.from({ length: 3 }).map((_, index) => (
              <React.Fragment key={index}>
                <li>
                  <Skeleton width={80} height={20} />
                </li>
                <hr />
              </React.Fragment>
            ))
          ) : (
            categoryData.map((item) => (
              <React.Fragment key={item.id}>
                <li
                  className={selectedCategory?.id === item.id ? 'active-link' : ''}
                  onClick={() => handleCategoryClick(item.id, item.name)}
                >
                  {item.name}
                </li>
                <hr />
              </React.Fragment>
            ))
          )}
        </ul>
      </nav>
    </div>
  );
}

export default CategoryNavBar;
