import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import TableComponent from '../../CommonComponents/TableComponent/TableComponent';
import {customerColumns} from "../../CommonComponents/TableComponent/EntityTables/CustomerTblColumns";
import CustomDropdown from '../../CommonComponents/custom-dropdown/CustomDropdown';
import { CiSearch } from 'react-icons/ci';
import { API_URL } from '../../../Constants/APIConstants';

function CustomerList(){
    const [paymentData, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      fetchCustomers();
      // document.addEventListener('mousedown', handleClickOutside);
      // return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);
  
    const fetchCustomers = async () => {
      try {
        const response = await axios.get(`${API_URL}/User/GetUsersList`);
        setData(response.data.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  
    return(
        <div className='list-container'>
        <h3>CUSTOMER LIST</h3>
        <div className='user-actions'>
            <div className='left'>
                <div className='search-bar'>
                    <CiSearch />
                    <input type='text' placeholder='Search...' />
                </div>
                <CustomDropdown
                    // options={cityNames}
                    // onSelect={(option) => console.log('Selected option:', option)}
                    placeholder="Select Area"
                />
                
                <button>GO</button>
            </div>
          
        </div>

        <TableComponent columns={customerColumns} data={paymentData}></TableComponent>
    </div>
    );
}
export default CustomerList