import React, { useState, useEffect, useRef, useContext } from 'react';
import './euipmentdetails.scss';
import { VscCompassActive } from "react-icons/vsc";
import { FaStar } from 'react-icons/fa';
import { LuStar } from 'react-icons/lu';
import { FaUserAlt } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';
import { FiChevronLeft } from "react-icons/fi";
import { GetEquipmentById, GetSupplierDetailsByEquipId } from '../../service/equipmentService';
import UserContext from '../../context/userContext';
import Skeleton from 'react-loading-skeleton';

function EquipmentDetails({showLogin, handleLoginClick}) {
    const navigate = useNavigate();
    const location = useLocation();
    const equipmentId = location.state?.equipment.id;
    const context = useContext(UserContext);
    const { user } = context;

    const [equipment, setEquipment] = useState(null);
    const [selectedImage, setSelectedImage] = useState('');
    const [showSupplierDetails, setShowSupplierDetails] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [supplierDetails, setSupplierDetails] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const reviewsPerPage = 5;

    const reviewsRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
          try {
            window.scrollTo(0, 0);

            if (equipmentId) {
              const response = await GetEquipmentById(equipmentId);
              setEquipment(response.data);
              setSelectedImage(response.data.image1Url);
            }
            setLoading(false);
          } catch (error) {
            setError(error.message);
            setLoading(false);
          }
        };

        fetchData();
    }, []);




      const renderStars = (rating) => {
        const fullStars = Math.floor(rating);
        const halfStar = rating % 1 >= 0.5;

        const stars = Array(5).fill(0).map((_, index) => {
          if (index < fullStars) {
            return <span key={index} className="filled-star">★</span>;
          } else if (index === fullStars && halfStar) {
            return <span key={index} className="filled-star" >☆</span>;
          } else {
            return <span key={index} className="empty-star" >☆</span>;
          }
        });

        return stars;
      };

      const totalPages = Math.ceil(equipment?.equipmentRatings.length / reviewsPerPage);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const currentReviews = equipment?.equipmentRatings.slice(
        (currentPage - 1) * reviewsPerPage,
        currentPage * reviewsPerPage
    );

    const calculateAverageRating = (ratings) => {
        if (ratings.length === 0) return 0;
        const totalRating = ratings.reduce((acc, rating) => acc + rating.starRating, 0);
        return (totalRating / ratings.length).toFixed(1);
    };

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
    };



    const getSupplierDetailButtonClick = async () => {
        if (!user) {
          showLogin();
          return;
        }

        try {
          const response = await GetSupplierDetailsByEquipId(equipmentId);
          console.log("Supplier Details ======>", response);

          setSupplierDetails(response);
          setShowSupplierDetails(true);
        } catch (error) {
          console.error("Error fetching supplier details:", error);
          setError(error.message);
        }
      };


    const scrollToReviews = () => {
        if (reviewsRef.current) {
            reviewsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const renderSkeleton = () => (
        <div className='equip-details-container'>
            <div className='details'>
                <div className='imgs-list'>
                    <Skeleton height={90} width={90} count={3} inline={true} />
                </div>
                <div className='left'>
                    <Skeleton height={300} />
                </div>
                <div className='right'>
                    <h1><Skeleton width={200} /></h1>
                    <div className='rent-detail'>
                        <p><Skeleton width={150} /></p>
                        <h2><Skeleton width={100} /></h2>
                    </div>
                    <div className='stars'>
                        <Skeleton width={100} />
                        <p className='review-count'><Skeleton width={50} /></p>
                    </div>
                    <Skeleton height={50} width={150} />
                </div>
            </div>
            <div className='description'>
                <Skeleton height={200} />
            </div>
        </div>
    );

    const renderPagination = () => {
        const totalPages = Math.ceil((equipment.equipmentRatings?.length || 0) / reviewsPerPage);
        if (totalPages <= 1) return null;

        return (
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={currentPage === index + 1 ? 'active' : ''}
              >
                {index + 1}
              </button>
            ))}
          </div>
        );
      };

    if (loading) {
        return renderSkeleton();
    }

    if (error) {
        return <div>Error loading equipment details: {error.message}</div>;
    }

    if (!equipment) {
        return <div>No equipment details available.</div>;
    }

    const averageRating = calculateAverageRating(equipment.equipmentRatings || []);
    const imageUrls = [equipment.image1Url, equipment.image2Url, equipment.image3Url].filter(Boolean);

    const reviewsToDisplay = equipment.equipmentRatings.slice(
        (currentPage - 1) * reviewsPerPage,
        currentPage * reviewsPerPage
      );

      const getRentalType = (type) => {
        switch (type) {
          case 0: return 'Hours';
          case 1: return 'Day';
          case 2: return 'Week';
          case 3: return 'Month';
          default: return '';
        }
      };

    return (
        <div className='equip-details-container'>
            <button className='back-btn' onClick={() => navigate(-1)}>
                <FiChevronLeft size={16} /><p>Back to Results</p>
            </button>
            <div className='details'>
                {imageUrls.length > 1 && (
                    <div className='imgs-list'>
                        {imageUrls.map((url, index) => (
                            <img
                                key={index}
                                src={url}
                                alt='equipment'
                                onClick={() => handleImageClick(url)}
                            />
                        ))}
                    </div>
                )}
                <div className='left'>
                    <img src={selectedImage} alt='equipment' />
                </div>
                <div className='right'>
                    <h1>{equipment.name}</h1>

                    <div className='rent-detail'>
                        <p>Rent Starts from</p>
                        <h2>₹ {equipment.rentalValue}/-
                        {getRentalType(equipment.rentalType)}
                        </h2>
                    </div>
                    <div className='stars'>
                        {renderStars(Number(averageRating))}
                        <p
                            className='review-count'
                            onClick={scrollToReviews}
                        >
                            ({equipment.equipmentRatings.length} Reviews)
                        </p>
                    </div>

                    {showSupplierDetails ? (
                        <div className='supplier-details'>
                            <p><strong>Supplier Name:</strong> {supplierDetails.supplierName}</p>
                            <p><strong>Contact No:</strong> {supplierDetails.supplierPhonenumber}</p>
                            {/* <p><strong>Email:</strong> {supplierDetails.email}</p> */}
                        </div>
                    ) : (
                        <button onClick={getSupplierDetailButtonClick}>Get Seller Details</button>
                    )}
                </div>
            </div>
            <div className='description'>
                <div className='title'><h2>Equipment overview</h2></div>
                <div className='grid-table'>

                        <div className='row'>
                            <VscCompassActive size={35} />
                            <div className='detail'>
                                <b><p>RENT</p></b>
                                <p>₹ {equipment.finalRentalValue}/-Day</p>
                            </div>
                        </div>
                        <div className='row'>
                            <VscCompassActive size={35} />
                            <div className='detail'>
                                <b><p>WORKING HOURS</p></b>
                                <p>{equipment.noOfWorkingHrs}</p>
                            </div>
                        </div>
                        <div className='row'>
                            <VscCompassActive size={35} />
                            <div className='detail'>
                                <b><p>REGISTRATION CERTIFICATE</p></b>
                                <p>{equipment.insuranceAvailable ? 'Available' : 'Not Available'}</p>
                            </div>
                        </div>


                        <div className='row'>
                            <VscCompassActive size={35} />
                            <div className='detail'>
                                <b><p>EQUIPMENT AVAILABLE AT</p></b>
                                <p>{equipment.city}, {equipment.state}, {equipment.country}, Pincode-{equipment.pinCode}</p>
                            </div>
                        </div>
                        <div className='row'>
                            <VscCompassActive size={35} />
                            <div className='detail'>
                                <b><p>REGISTRATION YEAR</p></b>
                                <p>{equipment.yearOfMfg ? equipment.yearOfMfg : 'Not Available'}</p>

                            </div>
                        </div>
                        <div className='row'>
                            <VscCompassActive size={35} />
                            <div className='detail'>
                                <b><p>INSURANCE AVAILABLE</p></b>
                                <p>{equipment.insuranceAvailable ? 'Available' : 'Not Available'}</p>
                            </div>
                        </div>

                        <div className='row'>
                            <VscCompassActive size={35} />
                            <div className='detail'>
                                <b><p>MODEL NUMBER</p></b>
                                <p>{equipment.modelNumber}</p>
                            </div>
                        </div>
                        <div className='row'>
                            <VscCompassActive size={35} />
                            <div className='detail'>
                                <b><p>MANUFACTURING YEAR</p></b>
                                <p>{equipment.yearOfMfg}</p>
                            </div>
                        </div>
                        {/* <div className='row'>
                            <VscCompassActive size={35} />
                            <div className='detail'>
                                <b><p>HYPO TO BANK</p></b>
                                <p>{equipment.hypoToBank ? 'Available' : 'Not Available'}</p>
                            </div>
                        </div> */}
                </div>
            </div>
            <div className='end-description'>
                <h4>Description</h4>
                {equipment.description ? (<p>{equipment.description}</p>) : (<p>No Description provided.</p>)}
            </div>
            <hr />
            <div className='end-description'>
                <h4>Youtube Link</h4>
                {equipment.youtubeLink ? (
                    <a href={equipment.youtubeLink} target="_blank" rel="noopener noreferrer">
                        {equipment.youtubeLink}
                    </a>
                ) : (
                    <p>No link provided.</p>
                )}
            </div>
            <hr />
            <div className='end-description' ref={reviewsRef}>
                <h4>Customer Reviews ({equipment.equipmentRatings.length} Reviews)</h4>
                {currentReviews.length > 0 ? (
                currentReviews.map((rating, index) => (
                    <div className='review' key={index}>
                    <FaUserAlt />
                    <div className='text-review'>
                        <b>{rating.userName}</b>
                        <p>{rating.comment}</p>
                        <div className='stars'>{renderStars(Math.round(rating.starRating))}</div>
                    </div>
                    </div>
                ))
                ) : (
                <p>No reviews submitted yet.</p>
                )}
                <div className='pagination'>
                            <button
                                className='page-btn'
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                &lt; Prev
                            </button>
                            <span className='page-indicator'>
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                className='page-btn'
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next &gt;
                            </button>
                        </div>
            </div>
        </div>
    );
}

export default EquipmentDetails;