import React, { useEffect, useRef, useState } from 'react';
import { IoCaretDownSharp, IoCaretUpSharp } from 'react-icons/io5';
import './customdropdown.scss';

const CustomDropdown = ({ options, onSelect, placeholder, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <label htmlFor={id} className="dropdown">
        <input
          type="text"
          id={id}
          value={selectedOption}
          onFocus={() => setIsOpen(true)}
          placeholder={placeholder}
          readOnly
        />
        {isOpen ? <IoCaretUpSharp /> : <IoCaretDownSharp />}
      </label>

      {isOpen && (
        <ul className="dropdown-options">
          <li
            onClick={() => handleSelect('')}
            className={selectedOption === '' ? 'selected' : ''}
          >
            None
          </li>
          {options.map((option, index) => (
            <li
              key={index}
              onClick={() => handleSelect(option)}
              className={selectedOption === option ? 'selected' : ''}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
