import React, { useEffect } from 'react';
import './contactus.scss';

function ContactUs() {
    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, []);
  return (
    <div className="contact-us-container">
      <h1>Contact Us</h1>
      <div className="last-updated">Last updated on 09-10-2024</div>
      <div className="contact-details">
        <p className="contact-item">
          Merchant Legal entity name:
          <span>SUVIDHA VISHWANATH KULKARNI</span>
        </p>
        <p className="contact-item">
          Registered Address:
          <span>FLAT NO. A2-1010, GAT NO-94, AISHWARYAM HAMARA PHASE 2, DEHU ALANDI ROAD, NEAR S N B P SCHOOL, CHIKHALI, PIMPRI CHINCHWAD, PUNE, MAHARASHTRA, 411062, PUNE, Maharashtra, PIN: 411062</span>
        </p>
        <p className="contact-item">
          Operational Address:
          <span>FLAT NO. A2-1010, GAT NO-94, AISHWARYAM HAMARA PHASE 2, DEHU ALANDI ROAD, NEAR S N B P SCHOOL, CHIKHALI, PIMPRI CHINCHWAD, PUNE, MAHARASHTRA, 411062, PUNE, Maharashtra, PIN: 411062</span>
        </p>
        <p className="contact-item">
          Telephone No:
          <span>9011037941</span>
        </p>
        <p className="contact-item">
          E-Mail ID:
          <span>akglobal2024@gmail.com</span>
        </p>
      </div>
      <div className="footer">
        For any further communication, feel free to contact us!
      </div>
    </div>
  );
}

export default ContactUs;
