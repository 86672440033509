const cities = [
    { id: 1, name: "Mumbai" },
    { id: 2, name: "Delhi" },
    { id: 3, name: "Bangalore" },
    { id: 4, name: "Chennai" },
    { id: 5, name: "Hyderabad" },
    { id: 6, name: "Ahmedabad" },
    { id: 7, name: "Kolkata" },
    { id: 8, name: "Pune" },
    { id: 9, name: "Jaipur" },
    { id: 10, name: "Surat" },
    { id: 11, name: "Lucknow" },
    { id: 12, name: "Kanpur" },
    { id: 13, name: "Indore" },
    { id: 14, name: "Thrissur" },
    { id: 15, name: "Coimbatore" },
    { id: 16, name: "Visakhapatnam" },
    { id: 17, name: "Bhopal" },
    { id: 18, name: "Vadodara" },
    { id: 19, name: "Patna" },
    { id: 20, name: "Nagpur" },
    { id: 21, name: "Ghaziabad" },
    { id: 22, name: "Meerut" },
    { id: 23, name: "Agra" },
    { id: 24, name: "Varanasi" },
    { id: 25, name: "Faridabad" },
    { id: 26, name: "Ludhiana" },
    { id: 27, name: "Chandigarh" },
    { id: 28, name: "Madurai" },
    { id: 29, name: "Navi Mumbai" },
    { id: 30, name: "Pimpri-Chinchwad" },
    { id: 31, name: "Aurangabad" },
    { id: 32, name: "Solapur" },
    { id: 33, name: "Jodhpur" },
    { id: 34, name: "Guwahati" },
    { id: 35, name: "Mohali" },
    { id: 36, name: "Mysore" },
    { id: 37, name: "Gwalior" },
    { id: 38, name: "Jabalpur" },
    { id: 39, name: "Srivastava Nagar" },
    { id: 40, name: "Amritsar" },
    { id: 41, name: "Allahabad" },
    { id: 42, name: "Ranchi" },
    { id: 43, name: "Bhubaneswar" },
    { id: 44, name: "Kochi" },
    { id: 45, name: "Thiruvananthapuram" },
    { id: 46, name: " Coimbatore" },
    { id: 47, name: "Vijayawada" },
    { id: 48, name: "Jamshedpur" },
    { id: 49, name: "Guntur" },
    { id: 50, name: "Raipur" },
  ];

  export default cities;