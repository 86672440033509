import React, { useEffect, useState } from 'react';
import './profile.scss';
import { API_URL } from '../../Constants/APIConstants';
import axios from 'axios';
import InputField from '../common-components/inputs/InputField';
import Checkbox from '../common-components/checkboxes/Checkbox';
import Button from '../common-components/buttons/Button';
import UploadImage from '../../components/CommonComponents/upload-image/UploadImage';


function Profile() {
  const [images, setImages] = useState(Array(4).fill(null));
  const [categories, setCategories] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [region, setRegion] = useState([]);
  const [yearOfMfg, setYearOfMfg] = useState([]);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobileNumber: '',
    panNumber: '',
    organizationName: '',
    cinNumber: '',
    gstNumber: '',
    address: '',
    city: '',
    state: '',
    pincode: '',
    email: '',
    altMobileNumber: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoryRes, subCategoryRes, regionRes, yearOfMfgRes] = await Promise.all([
          axios.get(`${API_URL}/DropDown/GetCategoryDropDown`),
          axios.get(`${API_URL}/DropDown/GetEquipmentTypeDropDown`),
          axios.get(`${API_URL}/DropDown/GetRegionDropDown`),
          axios.get(`${API_URL}/DropDown/GetMfgYearDropDown`)
        ]);
        setCategories(categoryRes.data);
        setSubCategory(subCategoryRes.data);
        setRegion(regionRes.data);
        setYearOfMfg(yearOfMfgRes.data);
      } catch (error) {
        console.error("There was an error fetching dropdown data!", error);
      }
    };

    fetchData();
  }, []);

  const handleImageSelected = (index, image) => {
    setImages((prevImages) => [...prevImages.slice(0, index), image, ...prevImages.slice(index + 1)]);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_URL}/Equipment/addnewequipment`, formData);
      console.log("Form submitted with response: ", response.data);
    } catch (error) {
      console.error("There was an error submitting the form!", error);
    }

    setFormData({
      firstName: '',
      lastName: '',
      mobileNumber: '',
      panNumber: '',
      organizationName: '',
      cinNumber: '',
      gstNumber: '',
      address: '',
      city: '',
      state: '',
      pincode: '',
      email: '',
      altMobileNumber: ''
    });
    setImages(Array(4).fill(null));
  };

  return (
    <div className='profile-container'>
      <form onSubmit={handleSubmit}>
        <h3>PROFILE</h3>
        <div className='info-field'>
          <UploadImage onImageSelected={handleImageSelected} />
          <div className='info-container'>
            <div className='split-input'>
              <InputField
                label='First Name'
                id='firstName'
                value={formData.firstName}
                onChange={handleChange}
              />
              <InputField
                label='Mobile Number'
                id='mobileNumber'
                value={formData.mobileNumber}
                onChange={handleChange}
              />
            </div>
            <div className='split-input'>
              <InputField
                label='Last Name'
                id='lastName'
                value={formData.lastName}
                onChange={handleChange}
              />
              <InputField
                label='PAN Number'
                id='panNumber'
                value={formData.panNumber}
                onChange={handleChange}
              />
            </div>
            <div className='check-bar'>
            <Checkbox label='Tick if Organization Details are Available.' />
            <hr/>

            </div>
          </div>
        </div>
        <InputField
          className='half'
          label='Organization Name'
          id='organizationName'
          value={formData.organizationName}
          onChange={handleChange}
        />
        <InputField
          className='half'
          label='CIN Number'
          id='cinNumber'
          value={formData.cinNumber}
          onChange={handleChange}
        />
        <InputField
          className='half'
          label='GST Number'
          id='gstNumber'
          value={formData.gstNumber}
          onChange={handleChange}
        />
        <hr />
        <InputField
          label='Address'
          id='address'
          value={formData.address}
          onChange={handleChange}
        />
        <div className='input-field'>
          <InputField
            label='City'
            id='city'
            value={formData.city}
            onChange={handleChange}
          />
          <InputField
            label='State'
            id='state'
            value={formData.state}
            onChange={handleChange}
          />
        </div>
        <div className='input-field'>
          <InputField
            label='Pincode'
            id='pincode'
            value={formData.pincode}
            onChange={handleChange}
          />
          <InputField
            label='Email'
            id='email'
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className='input-field'>
          <InputField
            label='Mobile Number'
            id='mobileNumber'
            value={formData.mobileNumber}
            onChange={handleChange}
          />
          <InputField
            label='Alt. Mobile No.'
            id='altMobileNumber'
            value={formData.altMobileNumber}
            onChange={handleChange}
          />
        </div>
        <div className='btns-container'>
          <Button type='submit' className='save-btn' label='Save'></Button>
          <Button type='button' className='cancel-btn' label="Cancel" onClick={() => setFormData({ ...formData, firstName: '' })  }></Button>
        </div>
      </form>
    </div>
  );
}

export default Profile;
