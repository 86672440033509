import React, { useState } from 'react';
import '../../../components/CommonComponents/FormStyle.scss'
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import axios from 'axios';
import { API_URL } from '../../../Constants/APIConstants';

function AddSubscription() {
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        displayName: '',
        price1Month: 0,
        discount1Month: 0,
        finalPrice1Month: 0,
        price2Month: 0,
        discount2Month: 0,
        finalPrice2Month: 0,
        price3Month: 0,
        discount3Month: 0,
        finalPrice3Month: 0,
        price1Year: 0,
        discount1Year: 0,
        finalPrice1Year: 0,
        equipmentCount: 0,
        contactToBeViewed: '',
        smsCountAllowed: '',
        active: true,
    });


    const calculateFinalValue = (subsValue, discount) => {
        const subs = parseFloat(subsValue) || 0;
        const disc = parseFloat(discount) || 0;
        return (subs - (subs * (disc / 100))).toFixed(2);
      };

      
    const handleChange = (e) => {
        const { id, value, type, checked } = e.target;
        const fieldValue = type === 'checkbox' ? checked : value;

        let updatedFormData = { ...formData, [id]: fieldValue };

        if (id === 'price1Month' || id === 'discount1Month') {
          updatedFormData.finalPrice1Month = calculateFinalValue(updatedFormData.price1Month, updatedFormData.discount1Month);
        }
        if (id === 'price2Month' || id === 'discount2Month') {
            updatedFormData.finalPrice2Month = calculateFinalValue(updatedFormData.price2Month, updatedFormData.discount2Month);
          }
          if (id === 'price3Month' || id === 'discount3Month') {
            updatedFormData.finalPrice3Month = calculateFinalValue(updatedFormData.price3Month, updatedFormData.discount3Month);
          }
          if (id === 'price1Year' || id === 'discount1Year') {
            updatedFormData.finalPrice1Year = calculateFinalValue(updatedFormData.price1Year, updatedFormData.discount1Year);
          }
        setFormData(updatedFormData);
        setErrors({ ...errors, [id]: '' });
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.displayName) newErrors.name = 'Display name is required';
        if (!formData.equipmentCount) newErrors.name = 'Enter count of equipments allowed.';
        if (!formData.finalPrice1Month) newErrors.name = 'Price is required';
        if (!formData.finalPrice2Month) newErrors.name = 'Price is required';
        if (!formData.finalPrice3Month) newErrors.name = 'Price is required';
        if (!formData.finalPrice1Year) newErrors.name = 'Price is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);

        if (!validateForm()) {
            return;
        }

        const formDataToSend = {
            name: formData.name,
            displayName: formData.displayName,
            price1Month: formData.price1Month,
            discount1Month: formData.discount1Month,
            finalPrice1Month: formData.finalPrice1Month,
            price2Month: formData.price2Month,
            discount2Month: formData.discount2Month,
            finalPrice2Month: formData.finalPrice2Month,
            price3Month: formData.price3Month,
            discount3Month: formData.discount3Month,
            finalPrice3Month: formData.finalPrice3Month,
            price1Year: formData.price1Year,
            discount1Year: formData.discount1Year,
            finalPrice1Year: formData.finalPrice1Year,
            equipmentCount: formData.equipmentCount,
            contactToBeViewed: 1, //formData.contactToBeViewed,
            smsCountAllowed: 1, //formData.smsCountAllowed,
            active: formData.active,
        };

        
    try {
        const response = await axios.post(`${API_URL}/Subscription/AddNewSubscription`, formDataToSend);
        toast.success('Subscription Created Successfully!', { variant: 'success' });
        //console.log("Form submitted with response: ", response.data);

      } catch (error) {
        toast.error("Failed to create subscription.", { variant: 'success' });
        console.error("There was an error submitting the form!", error);
      }
    }


    return (
        <div className='form-container'>
            <h3>Add Subscription</h3>
            <form onSubmit={handleSubmit}>

                <div className={`split-input `}>
                    <label className='input-label' htmlFor='name'>Name</label>
                    <input
                        className={`input-text ${errors.name ? 'error' : ''}`}
                        type='text'
                        id='name'
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <label className='input-label' htmlFor='name'>Display Name</label>
                    <input
                        className={`input-text ${errors.name ? 'error' : ''}`}
                        type='text'
                        id='displayName'
                        value={formData.displayName}
                        onChange={handleChange}
                    />
                </div>

                <div className={`split-input `}>
                    <label className='input-label' htmlFor='equipmentCount'>Equip. Count</label>
                    <input
                        className={`input-text ${errors.equipmentCount ? 'error' : ''}`}
                        type='text'
                        id='equipmentCount'
                        value={formData.equipmentCount}
                        onChange={handleChange}
                    />
                   
                </div>

                {/* For 1 Month */}
                <div className='split-input'>
                    <label className='input-label' htmlFor='rentalValue'>Price 1 Month</label>
                    <input
                        className={`input-text ${errors.price1Month ? 'error' : ''}`}
                        type='text'
                        id='price1Month'
                        value={formData.price1Month}
                        onChange={handleChange}
                    />
                    <label className='input-label' htmlFor='discount'>Disc. 1 Month(%)</label>
                    <input
                        className='input-text'
                        type='text'
                        id='discount1Month'
                        value={formData.discount1Month}
                        onChange={handleChange}
                    />
                    <label className='input-label' htmlFor='finalValue'>Final Price</label>
                    <input
                        className={`input-text `}
                        type='text'
                        id='finalPrice1Month'
                        value={formData.finalPrice1Month}
                        onChange={handleChange}
                        readOnly
                    />
                </div>

                {/* For 2 Month */}
                <div className='split-input'>
                    <label className='input-label' htmlFor='rentalValue'>Price 2 Month</label>
                    <input
                        className={`input-text ${errors.price2Month ? 'error' : ''}`}
                        type='text'
                        id='price2Month'
                        value={formData.price2Month}
                        onChange={handleChange}
                    />
                    <label className='input-label' htmlFor='discount'>Disc. 2 Month(%)</label>
                    <input
                        className='input-text'
                        type='text'
                        id='discount2Month'
                        value={formData.discount2Month}
                        onChange={handleChange}
                    />
                    <label className='input-label' htmlFor='finalValue'>Final Price</label>
                    <input
                        className={`input-text `}
                        type='text'
                        id='finalPrice2Month'
                        value={formData.finalPrice2Month}
                        onChange={handleChange}
                        readOnly
                    />
                </div>

                {/* For 3 Month */}
                <div className='split-input'>
                    <label className='input-label' htmlFor='rentalValue'>Price 3 Month</label>
                    <input
                        className={`input-text ${errors.price3Month ? 'error' : ''}`}
                        type='text'
                        id='price3Month'
                        value={formData.price3Month}
                        onChange={handleChange}
                    />
                    <label className='input-label' htmlFor='discount'>Disc. 3 Month(%)</label>
                    <input
                        className='input-text'
                        type='text'
                        id='discount3Month'
                        value={formData.discount3Month}
                        onChange={handleChange}
                    />
                    <label className='input-label' htmlFor='finalValue'>Final Price</label>
                    <input
                        className={`input-text `}
                        type='text'
                        id='finalPrice3Month'
                        value={formData.finalPrice3Month}
                        onChange={handleChange}
                        readOnly
                    />
                </div>

                {/* For  1 year */}
                <div className='split-input'>
                    <label className='input-label' htmlFor='rentalValue'>Price 1 Year</label>
                    <input
                        className={`input-text ${errors.price1Year ? 'error' : ''}`}
                        type='text'
                        id='price1Year'
                        value={formData.price1Year}
                        onChange={handleChange}
                    />
                    <label className='input-label' htmlFor='discount'>Disc. 1 Year(%)</label>
                    <input
                        className='input-text'
                        type='text'
                        id='discount1Year'
                        value={formData.discount1Year}
                        onChange={handleChange}
                    />
                    <label className='input-label' htmlFor='finalValue'>Final Price</label>
                    <input
                        className={`input-text `}
                        type='text'
                        id='finalPrice1Year'
                        value={formData.finalPrice1Year}
                        onChange={handleChange}
                        readOnly
                    />
                </div>

                <div className='btns-container'>
                    <button type='submit' className='save-btn'>SAVE</button>
                    <button type='button' className='cancel-btn' onClick={() => navigate(-1)}>CANCEL</button>
                </div>
            </form>


        </div>
    )
};

export default AddSubscription;

