import React, { useState, useEffect, useContext } from 'react';
import './dynamicequipment.scss';
import Equipment from '../../../CommonComponents/EquipmentCard/Equipment';
import axios from 'axios';
import { API_URL } from '../../../../Constants/APIConstants';
import FilterComponent from './filters/FilterComponent';
import Button from '../../../../dashboard/common-components/buttons/Button';
import CategoryNavBar from '../../../CommonComponents/categoryNavBar';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { GetActiveSubscriptionEquipments } from '../../../../service/equipmentService';
import { useLocation, useNavigate } from 'react-router-dom';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { motion } from 'framer-motion';
import { FiFilter } from 'react-icons/fi';
import { SearchContext } from '../../../../context/SearchContext ';
import useDebounce from '../../../../Debouncer';

function DynamicEquipment() {
    const navigate = useNavigate();
    const location = useLocation();
    const { searchQuery, setApplyFiltersCallback  } = useContext(SearchContext);
    const debouncedSearchQuery = useDebounce(searchQuery, 500);

    const [equipmentsData, setEquipmentsData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [equipmentType, setEquipmentType] = useState([]);
    const [zone, setZone] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [selectedFilters, setSelectedFilters] = useState({
        rentalHours: [5, 100],
        zone: [],
        equipmentType: [],
        discount: [],
        insurance: null,
    });
    const [tempFilters, setTempFilters] = useState(selectedFilters);
    const [isFilterBarVisible, setIsFilterBarVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const { selectedNavCategory } = location.state || {};

    const itemsPerPage = 20;

    console.log("this is from mainform",selectedNavCategory)

    useEffect(() => {
        fetchEquipmentCategories();
        fetchEquipmentType();
        fetchZone();
    }, []);

    useEffect(() => {
        setApplyFiltersCallback(() => applyFilters);
      }, [setApplyFiltersCallback]);

    useEffect(() => {
        setFilteredData(equipmentsData);
    }, [equipmentsData]);

    useEffect(() => {
        applyFilters();
    }, [selectedCategory, selectedFilters, debouncedSearchQuery]);

    const fetchEquipmentCategories = async () => {
        setLoading(true);
        try {
            const response = await GetActiveSubscriptionEquipments({
                searchTerm: debouncedSearchQuery || "",
                startRentprice: 0,
                endRentprice: 0,
                categoryId: '',
                insuranceAvailable: true,
                // discount : 0
              });
            console.log("API Response:", response);
            const data = response;
            setEquipmentsData(data);
        } catch (error) {
            console.error("Error fetching equipment data:", error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const toggleFilterBar = () => {
        setIsFilterBarVisible(!isFilterBarVisible);
    };

    const fetchEquipmentType = async () => {
        try {
            const response = await axios.get(`${API_URL}/DropDown/GetEquipmentTypeDropDown`);
            setEquipmentType(response.data);
        } catch (error) {
            setError(error);
        }
    };

    const fetchZone = async () => {
        try {
            const response = await axios.get(`${API_URL}/DropDown/GetRegionDropDown`);
            setZone(response.data);
        } catch (error) {
            setError(error);
        }
    };

    const applyFilters = async () => {
        let payload = {
            searchTerm: debouncedSearchQuery  || '',
            startRentprice: '',
            endRentprice: '',
            categoryId: selectedCategory.id !== 'All' ? selectedCategory.id : null,
            equipmentTypeId: tempFilters.equipmentType || [],
            insuranceAvailable: tempFilters.insurance !== null ? tempFilters.insurance : null,
            discount: tempFilters.discount.length > 0 ? Math.max(...tempFilters.discount) : 0,
        };

        try {
            setLoading(true);
            const response = await GetActiveSubscriptionEquipments(payload);
            setFilteredData(response);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
            setIsFilterBarVisible(false);
            setCurrentPage(1);
        }
    };

    const handleFilterChange = (filterType, value) => {
        setTempFilters(prevFilters => ({
            ...prevFilters,
            [filterType]: value,
        }));
    };

    const handleApplyFilters = () => {
        setSelectedFilters(tempFilters);
    };

    const insuranceAvailable = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
    ];

    const discountOptions = [
        { value: '10', label: '10% off or more' },
        { value: '25', label: '25% off or more' },
        { value: '35', label: '35% off or more' },
        { value: '50', label: '50% off or more' },
        { value: '60', label: '60% off or more' },
        { value: '70', label: '70% off or more' },
    ];

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={`page-button ${currentPage === i ? 'active' : ''}`}
                >
                    {i}
                </button>
            );
        }
        return pageNumbers;
    };

    return (
        <div className='dynamic-page-container'>
            <CategoryNavBar
  selectedCategory={selectedCategory}
  setSelectedCategory={setSelectedCategory}
  selectedNavCategory={selectedNavCategory}
/>
            <div className='title-bar'>
                <h2>{selectedCategory.name} EQUIPMENTS on rent</h2>
                <p>Pick the best equipment as per your need</p>
            </div>

            <div className='filter-icon' onClick={toggleFilterBar}>
                <FiFilter size={20} />
            </div>

            {/*this is the mobile Filter Bar */}

            {isFilterBarVisible && <div className='overlay' onClick={toggleFilterBar}></div>}

            <motion.div
                className={`mobile-filter-bar ${isFilterBarVisible ? 'visible' : ''}`}
                initial={{ translateY: '100%' }}
                animate={{ translateY: isFilterBarVisible ? '0%' : '100%' }}
                transition={{ type: "bounce", stiffness: 150 }}
            >
                <header>Filters</header>
                <FilterComponent
                    title="Choose Rental Hours"
                    isRangeSlider={true}
                    rangeMin={5}
                    rangeMax={100}
                    onChange={(value) => handleFilterChange('rentalHours', value)}
                />
                {/* <FilterComponent title="Zone" options={zone.map(type => ({ value: type.id, label: type.value }))} onChange={(value) => handleFilterChange('zone', value)} /> */}
                <FilterComponent title="Equipment Type" options={equipmentType.map(type => ({ value: type.id, label: type.value }))} onChange={(value) => handleFilterChange('equipmentType', value)} />
                <FilterComponent title="Choose Discount" options={discountOptions.map(option => ({ value: parseInt(option.value, 10), label: option.label }))} onChange={(value) => handleFilterChange('discount', value)} />
                <FilterComponent title="Insurance Available" options={insuranceAvailable} onChange={(value) => handleFilterChange('insurance', value)} type="radio" />
                <Button type='submit' className='save-btn' label='Apply' onClick={handleApplyFilters} />
            </motion.div>


            <div className='page-body'>

                <div className='filter-bar'>
                    <header>Filters</header>
                    <FilterComponent
                        title="Choose Rental Hours"
                        isRangeSlider={true}
                        rangeMin={5}
                        rangeMax={100}
                        onChange={(value) => handleFilterChange('rentalHours', value)}
                    />

                    {/* <FilterComponent title="Zone" options={zone.map(type => ({ value: type.id, label: type.value }))} onChange={(value) => handleFilterChange('zone', value)} /> */}
                    <FilterComponent visible title="Equipment Type" options={equipmentType.map(type => ({ value: type.id, label: type.value }))} onChange={(value) => handleFilterChange('equipmentType', value)} />
                    <FilterComponent title="Choose Discount" options={discountOptions.map(option => ({ value: parseInt(option.value, 10), label: option.label }))} onChange={(value) => handleFilterChange('discount', value)} />
                    <FilterComponent title="Insurance Available" options={insuranceAvailable} onChange={(value) => handleFilterChange('insurance', value)} type="radio" />
                    <Button type='submit' className='save-btn' label='Apply' onClick={handleApplyFilters} />
                </div>

                <div className='equipments-container'>
                    <div className='equipment-result'>
                    {loading ? (
                        <>
                        {[...Array(6)].map((_, index) => (
                          <div className="equipment-skeleton" key={index}>
                            <Skeleton height={200} width={150} />
                            <Skeleton width={100} />
                            <Skeleton width={60} />
                          </div>
                        ))}
                      </>
                    ) : (
                        <>
                            {currentItems.length > 0 ? (
                                currentItems.map((equipment, index) => (
                                    <Equipment
                                    key={index}
                                    status={equipment.status}
                                    name={equipment.name}
                                    img={equipment.image1Url}
                                    model={equipment.modelNumber}
                                    mfg={equipment.yearOfMfg}
                                    hours={equipment.noOfWorkingHrs}
                                    rental={equipment.rentalValue}
                                    finalValue={equipment.finalRentalValue}
                                    id={equipment.id}
                                    region={equipment.region}
                                    discount={equipment.discount}
                                    equipment={equipment}
                                />
                                ))
                            ) : (
                                <p>No Equipment found</p>
                            )}
                        </>
                    )}

                    </div>
                    <div className="pagination">
                        <button className='control-button' onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
                            First
                        </button>
                        <button className='control-button' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                            <FiChevronLeft/>
                        </button>
                        {renderPageNumbers()}
                        <button className='control-button' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                            <FiChevronRight/>
                        </button>
                        <button className='control-button' onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>
                            Last
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DynamicEquipment;
