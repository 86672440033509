import React, { useState } from 'react'
import './signup.scss'
import { IoIosClose } from 'react-icons/io'
import { Link, Route, Routes, useNavigate } from 'react-router-dom'
import Self from './pages/Self'
import Organization from './pages/Organization'
import Tab from './Tab'

function SignUp({onClose, showLogin}) {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    { label: 'SELF', component: <Self showLogin={showLogin} /> },
    { label: 'ORGANIZATION', component: <Organization showLogin={showLogin} /> },
  ];

  const goBack = () =>{
    navigate(-1)
  }

  return (
    <div className='signup-container'>
    <div className='signup-holder'>
        <div className='signup-head'>
            <h3>Sign Up</h3>


            <IoIosClose size={30} color='#252525' onClick={onClose} />
        </div>
        <div className='select-type'>
        {/* {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            onClick={() => setActiveTab(index)}
            isActive={activeTab === index}
          />
        ))} */}
        </div>

        <div className="tabs-content">
          {tabs[activeTab].component}
        </div>




    </div>
    </div>
  )
}

export default SignUp