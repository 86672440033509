
import { toast } from "sonner";
import { post } from "../APIHandler";

export const initiatePaymentSession = async (plan, customerDetails) => {
    try {
        const response = await post(`/Payment/payment`, {
            amount: plan.price,
            customerId: customerDetails.id,
            customerName: customerDetails.name,
            customerEmail: customerDetails.email,
            customerPhone: customerDetails.phone
        });

        if (response.data && response.data.payment_session_id) {
            return {
                sessionId: response.data.payment_session_id,
                orderId: response.data.order_id
            };
        } else {
            throw new Error("Invalid response for payment session initiation");
        }
    } catch (error) {
        console.error("Error initiating payment session:", error);
        toast.error("Failed to start the payment session. Please try again.");
        throw error;
    }
};


export const verifyPaymentService = async (orderId) => {
    try {
        const response = await post(`/Payment/VerifyPayment?id=${orderId}`);

        if (Array.isArray(response.data)) {
            return response.data;
        } else if (response.data && response.data.message) {

            throw new Error(response.data.message);
        } else {
            throw new Error("Failed to verify payment due to unexpected response format");
        }
    } catch (error) {
        console.error("Error verifying payment:", error);
        toast.error("Payment verification failed. Please contact support.");
        throw error;
    }
};

export const getPayments = async (filter = {}) => {
    try {
        const paymentData = await post('/Payment/GetPayments');
        console.log("Service Response ========>", paymentData);
        return paymentData;
    } catch (error) {
        console.error('Error fetching payments data', error);
        throw error;
    }
};

