import { toast } from "sonner";
import { post } from "../APIHandler";

export const signUpUser = async (formData) => {
    try {
        const response = await post('/Login/SignUp', formData);
        return response;
    } catch (error) {
        console.error('Error in registering new user ERROR:', error);
        throw error;
    }
};