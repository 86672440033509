import React, { useContext, useEffect, useRef, useState } from 'react'
import './recentvisited.scss'
import TableComponent from '../../components/CommonComponents/TableComponent/TableComponent';
import { equipmentColumns } from '../../components/CommonComponents/TableComponent/EntityTables/EquipmentTblColumns';
import { Link, useNavigate } from 'react-router-dom';
import CustomDropdown from '../../components/CommonComponents/custom-dropdown/CustomDropdown';
import axios from 'axios';
import { API_URL } from '../../Constants/APIConstants';
import { getEquipmentData, GetOwnerEquipments, GetOwnerRecentlyViewedEquipments } from '../../service/equipmentService';
import UserContext from '../../context/userContext';
import { LoadingContext } from '../../context/LoadingContext';
import Rating from './rating/Rating';
import { RecentlyvisitedColumn } from '../../components/CommonComponents/TableComponent/EntityTables/RecentlyvisitedColumn';



const flattenData = (data) => {
  return data.map(item => ({
    ...item,
    subCategory: item.equipmentType?.subType || ''
  }));
};
function RecentVisited() {
  const context = useContext(UserContext);
  const { user } = context;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const dropdownRef = useRef(null);

  const [equipmentsData, setEquipmentsData] = useState([]);
  const [filteredEquipments, setFilteredEquipments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [userRating, setUserRating] = useState(0);

  const handleRatingChange = (newRating) => {
    setUserRating(newRating);
  };

  const { showLoader, hideLoader } = useContext(LoadingContext);

  const navigate = useNavigate();

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    fetchEquipments();
    // fetchCategories();
    // fetchSubCategories();
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [user]);

  const fetchEquipments = async () => {
    try {
      showLoader();
      let data;
      if (user.role.toLowerCase() === 'admin') {
        data = await getEquipmentData();
      } else {
        data = await GetOwnerRecentlyViewedEquipments();
      }

      const flattenedData = flattenData(data.data);
      setEquipmentsData(flattenedData);
      setFilteredEquipments(flattenedData);
    } catch (err) {
      setError(err.message);
    } finally {
      hideLoader();
    }
  };

  // const fetchCategories = async () => {
  //   showLoader();
  //   try {
  //     const response = await axios.get(`${API_URL}/DropDown/GetCategoryDropDown`);
  //     setCategories(response.data);
  //   } catch (error) {
  //     setError(error.message);
  //   } finally {
  //     hideLoader();
  //   }
  // };

  // const fetchSubCategories = async () => {
  //   showLoader();
  //   try {
  //     const response = await axios.get(`${API_URL}/DropDown/GetEquipmentTypeDropDown`);
  //     setSubCategories(response.data);
  //   } catch (error) {
  //     setError(error.message);
  //   } finally {
  //     hideLoader();
  //   }
  // };

  const filterEquipments = () => {
    let filteredData = equipmentsData;

    if (searchQuery.trim() !== '') {
      filteredData = filteredData.filter((equipment) =>
        equipment.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        equipment.modelNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        equipment.region?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        equipment.subCategory.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (selectedCategory) {
      filteredData = filteredData.filter(equipment => equipment.category === selectedCategory);
    }

    if (selectedSubCategory) {
      filteredData = filteredData.filter(equipment => equipment.subCategory === selectedSubCategory);
    }

    setFilteredEquipments(filteredData);
  };

  const handleSelectCategory = (option) => {
    setSelectedCategory(option || '');
  };

  const handleSelectSubCategory = (option) => {
    setSelectedSubCategory(option || '');
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleView = (equipment) => {
    navigate(`/dashboard/view-equipment/${equipment.id}`);
  };

  const handleEdit = (equipment) => {
    navigate(`/dashboard/edit-equipment/${equipment.id}`);
  };

  return (
    <div className='list-container'>

      <h3>RECENTLY VISITED</h3>
      <div className='user-actions'>




      </div>
      <div className='table-container'>
        <TableComponent
          columns={RecentlyvisitedColumn}
          data={filteredEquipments}
          onView={handleView}
          onEdit={handleEdit}
          itemsPerPage={10}
          recentlyVisited={true}
        />
      </div>
    </div>
  );
}

export default RecentVisited