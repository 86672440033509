import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './subscriptionlist.scss';
import Button from '../../../dashboard/common-components/buttons/Button';
import TableComponent from '../../CommonComponents/TableComponent/TableComponent';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import UserContext from '../../../context/userContext';
import { API_URL } from '../../../Constants/APIConstants';
import { GetPurchasedSubscriptions, GetSubscriptions } from '../../../service/subscriptionService';
import { LoadingContext } from '../../../context/LoadingContext';

const SubscriptionList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [equipmentData, setEquipmentData] = useState();
    const context = useContext(UserContext);
    const { user, setUser } = context;
    const [data, setData] = useState([]);

    const { showLoader, hideLoader } = useContext(LoadingContext);

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchSubscriptions = async () => {
            // window.scrollTo(0, 0);
            try {
                showLoader();
                let response;
                if (user && user.role) {
                    if (user.role.toLowerCase() === 'admin') {
                        response = await GetSubscriptions();
                    }
                    // else if (user && user.role && (user.role.toLowerCase() === 'supplier') {
                    //     response = await GetPurchasedSubscriptions(user.loggedInUserId);
                    // } 
                    else {
                        response = await GetPurchasedSubscriptions(user.loggedInUserId);
                     //   console.error('User role is not recognized');
                        // return;
                    }
                }
                const transformedData = transformData(response);
                console.log("Data =====>", transformedData);
                setData(transformedData);
                hideLoader();
            } catch (error) {
                console.error('Error fetching subscription plans:', error);
                hideLoader();
            }
        };

        fetchSubscriptions();
    }, [user]);


    const transformData = (data) => {
        return data.map(plan => ({
            id: plan.id,
            subscriptionId : plan.subscriptionId,
            displayName: plan.displayName,
            startDate: plan.startDate,
            endDate: plan.endDate,
            finalPrice: plan.finalPrice,
            subscriptionPeriod: '1 year',
            equipmentCount: plan.equipmentCount,
            paymentMode: plan.paymentMode,

            smsServices: plan.smsCountAllowed > 0,
            emailServices: false,
            active: plan.active
        }));
    };

    const columns = [
        // { Header: 'Id', accessor: 'id' },
        { Header: 'Plan Name', accessor: 'displayName' },
        { Header: 'Start Date', accessor: 'startDate', format: 'date' },
        { Header: 'End Date', accessor: 'endDate', format: 'date' },
        { Header: 'Period', accessor: 'subscriptionPeriod' },
        { Header: 'Final Price', accessor: 'finalPrice' },
        { Header: 'Equipment Count', accessor: 'equipmentCount' },
        { Header: 'Payment Mode', accessor: 'paymentMode' },
        { Header: 'SMS Services', accessor: 'smsServices', type: 'checkbox' },
        { Header: 'Email Services', accessor: 'emailServices', type: 'checkbox' },
    ];

    const handleView = (row) => {
        console.log('View', row);
    };

    const handleEdit = (row) => {
        console.log('Edit', row);
    };

    const handleToggleChange = (rowIndex, accessor) => {
        const updatedData = [...data];
        updatedData[rowIndex][accessor] = !updatedData[rowIndex][accessor];
        setData(updatedData);
    };

    const handleCheckboxChange = (rowIndex, accessor) => {
        const updatedData = [...data];
        updatedData[rowIndex][accessor] = !updatedData[rowIndex][accessor];
        setData(updatedData);
    };

    return (
        <div className='subscriptionlist-container'>
            <header>
                <h3 className='heading'>Subscription List</h3>
                {user && user.role && user.role.toLowerCase() === 'admin' ?
                <Link to='/dashboard/subscription-add'>
                    <Button className='save-btn' label="Add Plan" />
                </Link>
                :
                <Link to='/dashboard/subscriptions'>
                    <Button className='save-btn' label="Buy Subscription" />
                </Link>
                }
            </header>
            <TableComponent
                columns={columns}
                data={data}
                onView={handleView}
                onEdit={handleEdit}
                onToggleChange={handleToggleChange}
                onCheckboxChange={handleCheckboxChange}
                hideActions={true}
                className="table-component"
                itemsPerPage="12"
                enableCollapsible={true}
            />
        </div>
    );
};

export default SubscriptionList;