import React from 'react';
import './inputField.scss';

const InputField = ({ label, id, value, onChange, type = 'text', className = '' }) => (
  <div className={`input-field ${className}`}>
    <label className='input-label' htmlFor={id}>{label}</label>
    <input
      className='equipment-input'
      type={type}
      id={id}
      value={value}
      onChange={onChange}
    />
  </div>
);

export default InputField;
