import React from 'react';
import '../addequipment.scss'

const InputField = ({ label, id, value, error, onChange, type = 'text', readOnly = false }) => {
  return (
    <div className='input-field'>
      <label htmlFor={id} className='input-label'>{label}</label>
      <input
        type={type}
        id={id}
        value={value}
        onChange={onChange}
        className={`equipment-input ${error ? 'error' : ''}`}
        readOnly={readOnly}
      />
      {/* {error && <span className='error-message'>{error}</span>} */}
    </div>
  );
};

export default InputField;
