import React, { useEffect, useRef } from 'react';
import './OtpModal.scss';

const OtpModal = ({ isOpen, onClose, otp, handleOtpChange, handleOtpSubmit, phoneNumber, handleResendOtp, timer, loading }) => {
  const inputRefs = useRef([]);

  useEffect(() => {
    if (isOpen && inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, [isOpen]);

  const handleChange = (e, index) => {
    const { value } = e.target;

    if (/^[0-9]$/.test(value)) {
      handleOtpChange(e, index);

      if (index < otp.length - 1 && value) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      handleOtpChange({ target: { value: '' } }, index);

      if (index > 0 && !e.target.value) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const isOtpFilled = () => {
    return otp.every((digit) => digit.trim() !== '');
  };

  const renderOtpInputs = () => {
    return otp.map((digit, index) => (
      <input
        key={index}
        type="text"
        maxLength="1"
        value={digit}
        onChange={(e) => handleChange(e, index)}
        onKeyDown={(e) => handleKeyDown(e, index)}
        className="otp-input"
        ref={(el) => (inputRefs.current[index] = el)}
      />
    ));
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="otp-modal-overlay">
      <div className="otp-modal">
        <span className="close-button" onClick={onClose}>&times;</span>
        <h4>Enter OTP Sent to +91 {phoneNumber}</h4>
        <form onSubmit={handleOtpSubmit}>
          <div className="otp-input-container">{renderOtpInputs()}</div>
          {timer > 0 ? (
            <p className="timer">Resend OTP in {timer} seconds</p>
          ) : loading ? (
            <p className="resend-btn">Sending...</p>
          ) : (
            <p className="resend-btn" onClick={handleResendOtp}>Resend OTP</p>
          )}
          <button type="submit" className="verify-button" disabled={!isOtpFilled()}>
            Verify OTP
          </button>
        </form>
      </div>
    </div>
  );
};

export default OtpModal;
