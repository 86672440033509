import React from 'react'
import './testimony.scss'
import Carousel from '../../../carousal/Carousel'

function Testimony() {
    return (
      <div className='testimony-container'>
          <div className='background-block'>
              <h2>Our Happy Renters and Partners</h2>


          <div className='testimony-list'>
              {/* <Carousel> */}
              <div className='testimony-item'>
                  <h3>Great Quality</h3>
                  <p>
                      I rented a backhoe loader machine,
                      and it was in great condition. The
                      staff and service were excellent.
                  </p>
                  <div className='user-info'>
                      <span>Dipul Kasar</span>
                      <p>Gujarat</p>
                  </div>
              </div>
              <div className='testimony-item'>
                  <h3>Affordable Pricing</h3>
                  <p>
                      Their listing rates are very affordable,
                      and the response of customers increased.
                      I will list all my machines on this website.
                  </p>
                  <div className='user-info'>
                      <span>Jaydeep Walke</span>
                      <p>Maharashtra</p>
                  </div>
              </div>
              <div className='testimony-item'>
                  <h3>Exceptional Service</h3>
                  <p>
                      Machineonrent is a game changer. Listing
                      my equipment is a breeze and the rentals
                      keep rolling in. Highly recommended.
                  </p>
                  <div className='user-info'>
                      <span>Mahantesh Telasang</span>
                      <p>Karnataka</p>
                  </div>
              </div>
              <div className='testimony-item'>
                  <h3>Top Equipments</h3>
                  <p>
                      I rented a generator for an event,
                      and it worked perfectly. The service
                      was excellent.
                  </p>
                  <div className='user-info'>
                      <span>Sachin Bhole</span>
                      <p>Maharashtra</p>
                  </div>
              </div>
              {/* </Carousel> */}
          </div>
          </div>
      </div>
    );
  }

  export default Testimony;