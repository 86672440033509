import React from 'react';
import './ToggleSwitch.scss';

const ToggleSwitch = ({ checked, onChange }) => {
    return (
        <label className="toggle-switch">
            <input type="checkbox" checked={checked} onChange={onChange} />
            <span className="toggle-slider"></span>
        </label>
    );
};

export default ToggleSwitch;
