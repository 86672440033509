import React from 'react';

const Checkboxes = ({ fields, onChange, readOnly = false }) => {
  return (
    <>
      {Array.isArray(fields) && fields.map((field) => (
        <div className='input-checkboxes' key={field.id}>
          <label htmlFor={field.id} className='input-label'>{field.label}</label>
          <input
            type='checkbox'
            id={field.id}
            checked={field.checked}
            onChange={(e) => onChange(e, field.id)}
            disabled={readOnly}
          />
        </div>
      ))}
    </>
  );
};

export default Checkboxes;
