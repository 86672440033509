import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import { CiSearch } from "react-icons/ci";
import CustomDropdown from "../../CommonComponents/custom-dropdown/CustomDropdown";
import TableComponent from "../../CommonComponents/TableComponent/TableComponent";
import {paymentColumns} from "../../CommonComponents/TableComponent/EntityTables/PaymentTblColumns";
import { API_URL } from '../../../Constants/APIConstants';

function PaymentList() {

  const [paymentData, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPayments();
    // document.addEventListener('mousedown', handleClickOutside);
    // return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const fetchPayments = async () => {
    try {
      const response = await axios.post(`${API_URL}/Payment/GetPayments`);
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }


    return (

        <div className='list-container'>
            <h3>Payment List</h3>
            <div className='user-actions'>
                <div className='left'>
                    <div className='search-bar'>
                        <CiSearch />
                        <input type='text' placeholder='Search...' />
                    </div>
                    {/* <CustomDropdown
                        // options={cityNames}
                        // onSelect={(option) => console.log('Selected option:', option)}
                        placeholder="Select Area"
                    /> */}

                    <button>GO</button>
                </div>

            </div>

            {/* <TableComponent columns={paymentColumns} data={paymentData}></TableComponent> */}
        </div>
    );


}

export default PaymentList