import axios from 'axios';
import { API_URL } from './Constants/APIConstants';
import { toast } from 'sonner';

const APIHandler = axios.create({
    baseURL: API_URL, // API base URL
});

APIHandler.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// GET request method
const get = async (url, config = {}) => {
    try {
        const response = await APIHandler.get(url, config);
        return response.data;
    } catch (error) {
        if (error.response.status == 400) {
            toast.error((error.response?.data || error.message), {
                variant: 'error',
                closeButton: true,
                duration: 1500
            });
        } else {
            // if (error.response.status == 400) {
                toast.error((error.response?.data || error.message), {
                    variant: 'error',
                    closeButton: true,
                    duration: 1500
                });
            // }
          //  throw error.response ? error.response.data : error;
            
        }
    }
};

// POST request method
const post = async (url, data, config = {}) => {
    try {
        const response = await APIHandler.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
                ...config.headers
            },
            ...config
        });

        return response.data;
    } catch (error) {
        if (error.response.status == 400) {
            toast.error((error.response?.data || error.message), {
                variant: 'error',
                closeButton: true,
                duration: 1500
            });
        } else {
             // if (error.response.status == 400) {
                toast.error((error.response?.data || error.message), {
                    variant: 'error',
                    closeButton: true,
                    duration: 1500
                });
            // }
            throw error.response ? error.response.data : error;
           
        }
    }
};

export { get, post };
