import React from 'react';
import './equipment.scss';
import { FaStar } from 'react-icons/fa';
import checkCircle from './assets/pngwing.com.png';
import { Link } from 'react-router-dom';
import { LuStar } from 'react-icons/lu';

function Equipment({ status, img, name, rent, color, equipment }) {
  const calculateAverageRating = (ratings) => {
    if (!ratings || ratings.length === 0) return 0;
    const totalRating = ratings.reduce((acc, rating) => acc + rating.starRating, 0);
    return totalRating / ratings.length;
  };

  const renderStars = (rating) => {
    const totalStars = 5;
    const validRating = Math.round(rating); // Round to nearest integer
    const filledStars = Array(validRating).fill(<FaStar size={14} className="filled-star" />);
    const emptyStars = Array(totalStars - validRating).fill(<LuStar size={14} className="empty-star" />);
    return [...filledStars, ...emptyStars];
  };

  const handleImageError = (event) => {
    event.target.src = "https://i.pinimg.com/236x/17/69/0c/17690cee2d63c20055ca70692f353e72.jpg";
  };

  let originalPrice = equipment.rentalValue;
  if (equipment && equipment.discount != null) {
    const discountPercentage = equipment.discount / 100;
    if (discountPercentage > 0 && discountPercentage < 1) {
      originalPrice = Math.round(equipment.rentalValue / (1 - discountPercentage));
    }
  }
// console.log(originalPrice)

const getRentalType = (type) => {
  switch (type) {
    case 0: return 'Hours';
    case 1: return 'Day';
    case 2: return 'Week';
    case 3: return 'Month';
    default: return '';
  }
};

  const cardColor = equipment?.categories?.[0]?.colorCode || '#FFF7E7';
  const averageRating = calculateAverageRating(equipment.equipmentRatings);

  return (
    <Link to='/equipment-details' state={{ equipment }}>
      <div className='equipment-card' style={{ background: cardColor }}>
        {status && (
          <div className='status'>
            <img src={checkCircle} alt='check circle' />
            <p>{status}</p>
          </div>
        )}
        <img src={img} alt='card-img' onError={handleImageError} />
        <h3>{name}</h3>
        <div className='stars'>
          {renderStars(averageRating)}
        </div>
        <div className='card-bottom'>
          <p>
            Rent Starts From <br />
            <span className='price'>
                <span className='discount-price'>₹ {equipment.rentalValue}/-</span>
              <p className='rent' style={{ marginLeft: "3px" }}> ₹ {Math.round(equipment.finalRentalValue)}/- 
              {getRentalType(equipment.rentalType)}
              </p>
              <span className='discount'>{equipment.discount}% <p>Off</p></span>
            </span>
          </p>
        </div>
      </div>
    </Link>
  );
}

export default Equipment;
