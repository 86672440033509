import React from 'react';
import './Button.scss';

const Button = ({ label, onClick, type = 'button', className, disabled, tooltip }) => {
  return (
    <div className="tooltip-container">
      <button
        type={type}
        className={`btn ${className}`}
        onClick={onClick}
        disabled={disabled}
      >
        {label}
      </button>
      {disabled && tooltip && (
        <span className="tooltip-text">
          {tooltip}
        </span>
      )}
    </div>
  );
};

export default Button;