import axios from 'axios';
import { post } from '../APIHandler';

export const fetchCategories = async () => {
  try {
    const response = await post(`/EquipmentType/GetEquipmentType`);
    return response;
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
};

export const addCategory = async (newCategory) => {
  try {
    const response = await post(`/EquipmentType/AddEquipmentType`, {
      type: newCategory.type,
      subType: newCategory.subType,
      active: newCategory.active || true,
      deleted: false,
    });
    return response.data;
  } catch (error) {
    console.error('Error adding category:', error);
    throw error;
  }
};

export const updateCategory = async (category) => {
  try {
    await post(`/EquipmentType/UpdateEquipmentType`, {
      id: category.id,
      type: category.type,
      subType: category.subType,
      active: category.active,
      deleted: category.deleted || false,
    });
  } catch (error) {
    console.error('Error updating category:', error);
    throw error;
  }
};

export const toggleCategoryStatus = async (category) => {
  try {
    await post(`/EquipmentType/UpdateEquipmentType`, {
        id: category.id,
        type: category.type,
        subType: category.subType,
        active: category.active,
        deleted: category.deleted || false,
    });
  } catch (error) {
    console.error('Error toggling category status:', error);
    throw error;
  }
};
