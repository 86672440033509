import { get, post } from "../APIHandler";
import { toast } from "sonner";

export const getEquipmentData = async (filter = {}) => {
    try {
        const equipmentData = await post('/Equipment/getallequipments', filter);
        console.log("Service Response ========>", equipmentData);
        return equipmentData;
    } catch (error) {
        console.error('Error fetching equipment data', error);
        throw error;
    }
};


export const GetActiveSubscriptionEquipments = async (filters = {}) => {
    const payload = {
        searchTerm: filters.searchTerm || "",
        startRentprice: filters.startRentprice || 0,
        endRentprice: filters.endRentprice || 0,
        categoryId: filters.categoryId || "",
        equipmentTypeId: filters.equipmentTypeId || [],
        insuranceAvailable: filters.insuranceAvailable,
        discount: filters.discount || 0
    };

    try {
        const equipmentData = await post('/Equipment/GetActiveSubscriptionEquipments', payload);
        console.log("Service Response ========>", equipmentData);
        return equipmentData;
    } catch (error) {
        console.error('Error fetching equipment data', error);
        throw error;
    }
};


export const GetEquipmentById = async (equipmentId) => {
    try {
        const equipmentData = await get(`/Equipment/GetEquipmentById?id=${equipmentId}`);
        return equipmentData;
    } catch (error) {
        console.error('Error fetching equipment data', error);
        throw error;
    }
};

export const getSupplierOwnedEquipment = async (filter = {}) => {
    try {
        const equipmentData = await post('/Equipment/GetOwnerEquipments', filter);
        console.log("Service Response ========>", equipmentData);
        return equipmentData;
    } catch (error) {
        console.error('Error fetching equipment data', error);
        throw error;
    }
};

export const addNewEquipment = async (equipment) => {
    try {
        const response = await post('/Equipment/addnewequipment', equipment);
        return response;
    } catch (error) {
        console.error('Error adding equipment.', error);
        throw error;
    }
};

export const GetOwnerEquipments = async (filter = {}) => {
    const payload = {
        supplierId : filter.supplierId,
    }
    try {
        const equipmentData = await post('/Equipment/GetOwnerEquipments', payload);
        console.log("Service Response ========>", equipmentData);
        return equipmentData;
    } catch (error) {
        console.error('Error fetching equipment data', error);
        throw error;
    }
};

export const GetOwnerRecentlyViewedEquipments = async (filter = {}) => {
    try {
        const equipmentData = await post('/Equipment/GetRecentViewedEquipment', filter);
        console.log("Service Response ========>", equipmentData);
        return equipmentData;
    } catch (error) {
        console.error('Error fetching equipment data', error);
        throw error;
    }
};

export const GetSupplierDetailsByEquipId = async (equipmentId) => {
    try {
        const supplierData = await get(`/Equipment/GetSupplierDetails?id=${equipmentId}`);
        return supplierData;
    } catch (error) {
        console.error('Error fetching equipment data', error);
        throw error;
    }
};