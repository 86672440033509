import React, { useState } from 'react';
import './settings.scss'; // Assuming you're using SCSS for styling

function Settings() {
  const [receiveNotifications, setReceiveNotifications] = useState(false);

  const handleCheckboxChange = (e) => {
    setReceiveNotifications(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can add your submit logic here
    console.log('Receive Notifications:', receiveNotifications);
  };

  return (
    <div className='setting-container'>
      <h3>EQUIPMENT LIST</h3>
      <form onSubmit={handleSubmit}>
        <div className='notification-setting'>
          <label htmlFor='receiveNotifications'>
            Receive customer notifications by SMS
          </label>
          <input
            type='checkbox'
            id='receiveNotifications'
            checked={receiveNotifications}
            onChange={handleCheckboxChange}
          />
        </div>

        <div className='submit-container'>
          <button type='submit' className='submit-btn'>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default Settings;
