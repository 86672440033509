import React from 'react';
import Dropdown from '../add-equipment-dropdown/DropDown';
import '../addequipment.scss';

const DropdownField = ({ label, id, value, options, error, onChange, multiple, reset, readOnly = false, disable }) => {
  const handleChange = (selectedValues) => {
    onChange(id, selectedValues);
  };

  return (
    <div className='input-field'>
      <label htmlFor={id} className='input-label'>{label}</label>
      <Dropdown
        id={id}
        value={value}
        label={label}
        options={options}
        error={error}
        onChange={handleChange}
        multiple={multiple}
        reset={reset}
        // readOnly={readOnly}
        disable={disable}
      />
    </div>
  );
};

export default DropdownField;
