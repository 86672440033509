import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Addequipment from '../Addequipment';
import { API_URL } from '../../../Constants/APIConstants';
import { GetEquipmentById } from '../../../service/equipmentService';

const EditEquipment = () => {
  const { id } = useParams();
  const [equipmentData, setEquipmentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
        const response = await GetEquipmentById(id);
        setEquipmentData(response.data);
        setLoading(false);
    }
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();

  }, []);

  // useEffect(() => {
  //   const fetchEquipment = async () => {
  //     try {
  //       const response = await axios.get(`${API_URL}/Equipment/GetEquipmentById?id=${id}`);
  //       setEquipmentData(response.data);
  //     } catch (err) {
  //       setError(err.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchEquipment();
  // }, [id]);

  console.log("edit equipment",equipmentData)

  const handleSave = async (updatedData) => {
    try {
      await axios.post(`${API_URL}/Equipment/UpdateEquipment`, updatedData);
      navigate('/dashboard/equipments-list');
    } catch (err) {
      setError(err.message);
    }
  };


  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Addequipment equipmentData={equipmentData} onSave={handleSave} />
  );
};

export default EditEquipment;
