import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { IoCaretDownSharp, IoCaretUpSharp } from "react-icons/io5";
import Slider from 'react-slider';
import './filtercomponent.scss';

const FilterComponent = ({
  title,
  options,
  onChange,
  type = 'checkbox',
  visible = false,
  isRangeSlider = false,
  rangeMin = 0,
  rangeMax = 100,
  selectedFilters = [],  // Pass this from the parent component to store selected filters
}) => {
  const [isVisible, setIsVisible] = useState(visible);
  const [selectedOptions, setSelectedOptions] = useState(type === 'checkbox' ? [] : '');
  const filterOptionsRef = useRef(null);
  const [range, setRange] = useState([rangeMin, rangeMax]);

  useEffect(() => {
    // Sync with parent-provided selected filters if necessary
    if (selectedFilters.length > 0) {
      setSelectedOptions(selectedFilters);
    }
  }, [selectedFilters]);

  const handleToggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleOptionChange = (option) => {
    if (type === 'checkbox') {
      const newSelectedOptions = selectedOptions.includes(option)
        ? selectedOptions.filter((item) => item !== option)
        : [...selectedOptions, option];
      setSelectedOptions(newSelectedOptions);
      onChange(newSelectedOptions); // Pass new options to parent
    } else if (type === 'radio') {
      setSelectedOptions(option);
      onChange(option); // Pass selected radio value to parent
    }
  };

  const handleRangeChange = (value) => {
    setRange(value);
    onChange(value); // Pass range slider values to parent
  };

  const handleScroll = (e) => {
    if (filterOptionsRef.current && filterOptionsRef.current.contains(e.target)) {
      e.stopPropagation();
      filterOptionsRef.current.scrollTop += e.deltaY;
    }
  };

  useEffect(() => {
    const filterOptionsElement = filterOptionsRef.current;
    if (filterOptionsElement) {
      filterOptionsElement.addEventListener('wheel', handleScroll);
    }
    return () => {
      if (filterOptionsElement) {
        filterOptionsElement.removeEventListener('wheel', handleScroll);
      }
    };
  }, []);

  return (
    <div className="filter-component">
      <div className="filter-header" onClick={handleToggleVisibility}>
        <span>{title}</span>
        {isVisible ? <IoCaretUpSharp /> : <IoCaretDownSharp />}
      </div>
      {isVisible && (
        <div className="filter-options" ref={filterOptionsRef}>
          {isRangeSlider ? (
            <div className="range-slider-container">
              <Slider
                className="range-slider"
                min={rangeMin}
                max={rangeMax}
                value={range}
                onChange={handleRangeChange}
                withBars
                pearling
                minDistance={10}
                renderTrack={(props, state) => {
                  const left = `${(state.value[0] / (rangeMax - rangeMin)) * 100}%`;
                  const width = `${((state.value[1] - state.value[0]) / (rangeMax - rangeMin)) * 100}%`;
                  return (
                    <>
                      <div {...props} className="slider-track" />
                      <div
                        {...props}
                        className="slider-track slider-track-middle"
                        style={{ left, width }}
                      />
                    </>
                  );
                }}
                renderThumb={(props) => <div {...props} className="slider-handle" />}
              />
              <div className="range-values">
                <span>{range[0]}</span>
                <span>{range[1]}</span>
              </div>
            </div>
          ) : (
            options.map((option) => (
              <label key={option.value}>
                <input
                  type={type}
                  value={option.value}
                  checked={
                    type === 'checkbox'
                      ? selectedOptions.includes(option.value)
                      : selectedOptions === option.value
                  }
                  onChange={() => handleOptionChange(option.value)}
                />
                <span className={type === 'checkbox' ? 'custom-checkbox' : 'custom-radio'}></span>
                {option.label}
              </label>
            ))
          )}
        </div>
      )}
    </div>
  );
};

FilterComponent.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
        .isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['checkbox', 'radio']),
  isRangeSlider: PropTypes.bool,
  rangeMin: PropTypes.number,
  rangeMax: PropTypes.number,
  selectedFilters: PropTypes.array, // Parent should pass the selected filters
};

FilterComponent.defaultProps = {
  type: 'checkbox',
  isRangeSlider: false,
  rangeMin: 0,
  rangeMax: 100,
  selectedFilters: [], // Default to an empty array for selected filters
};

export default FilterComponent;
