import mumbaiIcon from './assets/mumbai.avif';
import delhiIcon from './assets/delhi.avif';
import bangaloreIcon from './assets/bangalore.avif';
import chennaiIcon from './assets/chennai.avif';
import hyderabadIcon from './assets/hyderabad.avif';
import puneIcon from './assets/pune.png';
import kolkataIcon from './assets/kolkata.avif';
import kochiIcon from './assets/kochi.avif';
import chandigarhIcon from './assets/chandigarh.avif';
import ahmedabadIcon from './assets/ahmedabad.avif';

const cities = [
    { id: 1, name: "Mumbai", iconUrl: mumbaiIcon },
    { id: 2, name: "Delhi", iconUrl: delhiIcon },
    { id: 3, name: "Bangalore", iconUrl: bangaloreIcon },
    { id: 4, name: "Chennai", iconUrl: chennaiIcon },
    { id: 5, name: "Hyderabad", iconUrl: hyderabadIcon },
    { id: 6, name: "Ahmedabad", iconUrl: ahmedabadIcon },
    { id: 7, name: "Kolkata", iconUrl: kolkataIcon },
    { id: 8, name: "Pune", iconUrl: puneIcon },
    { id: 9, name: "Kochi", iconUrl: kochiIcon },
    { id: 10, name: "Chandigarh", iconUrl: chandigarhIcon },
    { id: 11, name: "Surat" },
    { id: 12, name: "Jaipur" },
    { id: 13, name: "Lucknow" },
    { id: 14, name: "Kanpur" },
    { id: 15, name: "Nagpur" },
    { id: 16, name: "Patna" },
    { id: 17, name: "Indore" },
    { id: 18, name: "Bhopal" },
    { id: 19, name: "Ludhiana" },
    { id: 20, name: "Agra" },
    { id: 21, name: "Varanasi" },
    { id: 22, name: "Vadodara" },
    { id: 23, name: "Guwahati" },
    { id: 24, name: "Thiruvananthapuram" },
    { id: 25, name: "Coimbatore" },
    { id: 26, name: "Vijayawada" },
    { id: 27, name: "Mysore" },
    { id: 28, name: "Nashik" },
    { id: 29, name: "Rajkot" },
    { id: 30, name: "Jodhpur" },
    { id: 31, name: "Raipur" },
    { id: 32, name: "Gwalior" },
    { id: 33, name: "Madurai" },
    { id: 34, name: "Jamshedpur" },
    { id: 35, name: "Meerut" },
    { id: 36, name: "Aurangabad" },
    { id: 37, name: "Ranchi" },
    { id: 38, name: "Amritsar" },
    { id: 39, name: "Jabalpur" },
    { id: 40, name: "Faridabad" },
    { id: 41, name: "Ghaziabad" },
    { id: 42, name: "Dhanbad" },
    { id: 43, name: "Allahabad" },
    { id: 44, name: "Vasai-Virar" },
    { id: 45, name: "Noida" },
    { id: 46, name: "Pimpri-Chinchwad" },
    { id: 47, name: "Kalyan-Dombivli" },
    { id: 48, name: "Rourkela" },
    { id: 49, name: "Salem" },
    { id: 50, name: "Warangal" }
];

export default cities;
