import { get, post } from "../APIHandler";

export const getCategoriesData = async (filter = {}) => {
    try {
        const categoryData = await get('/Category/GetCategories');
        return categoryData;
    } catch (error) {
        console.error('Error fetching equipment data', error);
        throw error;
    }
};

export const getCategoryDropdownOptions = async () =>{
  try {
    const responseData = await get('/DropDown/GetCategoryDropDown');
    return responseData;

  } catch (error) {
        console.error('Error fetching category data', error);
        throw error;
  }
}

export const getSubCategoryDropdownOptions = async () =>{
  try {
    const responseData = await get('/DropDown/GetEquipmentTypeDropDown');
    return responseData;

  } catch (error) {
        console.error('Error fetching sub category data', error);
        throw error;
  }
}

export const fetchEquipmentTypes = async () => {
  try {
    const response = await get(`/Category/GetCategories`);
    return response;
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
};

export const addEquipmentType = async (newCategory) => {
  try {
    const response = await post(`/Category/AddNewCategory`, newCategory);
    return response.data;
  } catch (error) {
    console.error('Error adding category:', error);
    throw error;
  }
};

export const updateEquipmentType = async (updatedCategory) => {
  try {
    await post(`/Category/UpdateCategory`, updatedCategory);
  } catch (error) {
    console.error('Error updating category:', error);
    throw error;
  }
};
