import React from 'react'
import './organization.scss'
import { Link } from 'react-router-dom'

function Organization({showLogin}) {
  return (
    <div className='oranization-container'>
           <div className='input-field'>
          <input
            className='equipment-input'
            type='text'
            id='company-name'
            placeholder='Company Name'

          />
        </div>

        <div className='split-input'>
            <input
                className='equipment-input'
                type='text'
                id='designation'
                placeholder='Designation'

              />
            <input
                className='equipment-input'
                type='text'
                id='cin'
                placeholder='CIN Number'

              />
        </div>
        <div className='split-input'>
            <input
                className='equipment-input'
                type='text'
                id='web-url'
                placeholder='Website url'

              />
            <input
                className='equipment-input'
                type='text'
                id='company-email'
                placeholder='Company-email'

              />
        </div>
        <div className='split-input'>
            <input
                className='equipment-input'
                type='text'
                id='firstname'
                placeholder='Firstname'

              />
            <input
                className='equipment-input'
                type='text'
                id='lastname'
                placeholder='Lastname'

              />
        </div>
        <div className='split-input'>
            <input
                className='equipment-input'
                type='email'
                id='email'
                placeholder='Email Id'

              />
            <input
                className='equipment-input'
                type='text'
                id='mobile'
                placeholder='Mobile Number'

              />
        </div>

        <div className='input-field'>
          <input
            className='equipment-input'
            type='text'
            id='address'
            placeholder='Address'

          />
        </div>

        <div className='split-input'>
            <input
                className='equipment-input'
                type='email'
                id='location'
                placeholder='Location'

              />
            <input
                className='equipment-input'
                type='text'
                id='pincode'
                placeholder='Pincode'

              />
        </div>

      <div className='checkboxes'>
        <div className='box'>
          <input type="checkbox" id="terms" name="terms" />
          <label for="terms">
            I agree to the
            <a href="/terms-and-conditions" class="terms-link" target="_blank"><b> Terms & Conditions</b></a>
          </label>
          <br />
        </div>

            <div className='box'>
              <input type="checkbox" id="update-box" name="update-box" />
              <label for="update-box">I would like to be informed about the latest updates from MachinesOnRent India.</label><br/>

            </div>
        </div>

        <div className='auth-btn'>
              <button>Sign Up</button>
              <p>Have an account? <b onClick={showLogin}>Login Now</b></p>
        </div>
    </div>
  )
}

export default Organization