import React, { useState } from 'react';
import './passwordmodal.scss';

const PasswordModal = ({ isOpen, onClose, password, confirmPassword, setPassword, setConfirmPassword, handlePasswordSubmit }) => {
  const [error, setError] = useState('');

  if (!isOpen) return null;

  const validatePassword = () => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      return 'Password must be at least 8 characters long, with one uppercase letter, one number, and one special character.';
    }
    if (password !== confirmPassword) {
      return 'Passwords do not match.';
    }
    return '';
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationError = validatePassword();
    if (validationError) {
      setError(validationError);
    } else {
      setError('');
      handlePasswordSubmit(e);
    }
  };

  const isPasswordLongEnough = password.length >= 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[@$!%*?&]/.test(password);
  const passwordsMatch = password === confirmPassword;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Set Your Password</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{
              borderColor:
                isPasswordLongEnough && hasUppercase && hasNumber && hasSpecialChar
                  ? 'green'
                  : 'red'
            }}
          />
          <ul className="password-hints">
            <li style={{ color: isPasswordLongEnough ? 'green' : 'red' }}>
              {isPasswordLongEnough ? '✔' : '✖'} At least 8 characters long
            </li>
            <li style={{ color: hasUppercase ? 'green' : 'red' }}>
              {hasUppercase ? '✔' : '✖'} Contains an uppercase letter
            </li>
            <li style={{ color: hasNumber ? 'green' : 'red' }}>
              {hasNumber ? '✔' : '✖'} Contains a number
            </li>
            <li style={{ color: hasSpecialChar ? 'green' : 'red' }}>
              {hasSpecialChar ? '✔' : '✖'} Contains a special character (@, $, !, %, *, ?, &)
            </li>
          </ul>
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            style={{
              borderColor: passwordsMatch ? 'green' : 'red'
            }}
          />
          {error && <p className="error-message">{error}</p>}
          <button type="submit">Submit</button>
        </form>
        <span className="close-button" onClick={onClose}>&times;</span>
      </div>
    </div>
  );
};

export default PasswordModal;
