import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Addequipment from '../Addequipment';
import { API_URL } from '../../../Constants/APIConstants';
import { GetEquipmentById } from '../../../service/equipmentService';

const ViewEquipment = () => {
  const { id } = useParams();
  const [equipmentData, setEquipmentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Define an async function inside the useEffect
    const fetchData = async () => {
      try {
        if (id) {
        const response = await GetEquipmentById(id);
        setEquipmentData(response.data);
        setLoading(false);
    }
      } catch (error) {
        setError(error.message); // Set the error state
        setLoading(false); // Stop loading even if there's an error
      }
    };

    fetchData(); // Call the async function

  }, []); // Empty array ensures this runs only once on mount

  console.log("edit equipment",equipmentData)

  const handleSave = async (updatedData) => {
    try {
      await axios.post(`${API_URL}/Equipment/UpdateEquipment`, updatedData);
      navigate('/dashboard/equipments-list');
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Addequipment equipmentData={equipmentData} view={true} />
  );
};

export default ViewEquipment;
