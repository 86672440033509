import React, { useEffect } from 'react';
import './termsconditions.scss';

function TermsConditions() {
    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, []);
  return (
    <div className="terms-conditions-container">
      <h1>Terms & Conditions</h1>
      <div className="last-updated">
        Last updated on 09-10-2024
      </div>
      <div className="terms-text">
        <p>
          These Terms and Conditions, along with privacy policy or other terms (“Terms”)
          constitute a binding agreement by and between SUVIDHA VISHWANATH KULKARNI,
          (“Website Owner” or “we” or “us” or “our”) and you (“you” or “your”) and relate to your
          use of our website, goods (as applicable) or services (as applicable) (collectively,
          “Services”).
        </p>
        <p>
          By using our website and availing the Services, you agree that you have read and accepted
          these Terms (including the Privacy Policy). We reserve the right to modify these Terms at
          any time and without assigning any reason. It is your responsibility to periodically review
          these Terms to stay informed of updates.
        </p>
        <p>
          The use of this website or availing of our Services is subject to the following terms of use:
        </p>
        <ul>
          <li>Provide true, accurate, and complete information during registration.</li>
          <li>No warranties or guarantees on accuracy or performance of information.</li>
          <li>Your use of Services is at your own risk and discretion.</li>
          <li>You do not own the content of this website or the Services.</li>
          <li>Unauthorized use may lead to legal action.</li>
          <li>Payment is required for services.</li>
          <li>No unlawful usage of the website or Services.</li>
          <li>We are not responsible for third-party websites linked on our site.</li>
          <li>You can claim refunds if Services are not provided.</li>
        </ul>
        <p>
          Notwithstanding anything contained in these Terms, the parties shall not be liable for any
          failure to perform an obligation under these Terms if performance is prevented or delayed
          by a force majeure event.
        </p>
        <p>
          These Terms and any dispute or claim relating to it, or its enforceability, shall be governed
          by and construed in accordance with the laws of India. All disputes shall be subject to the
          exclusive jurisdiction of the courts in PUNE, Maharashtra.
        </p>
      </div>
      <div className="footer">
        All concerns or communications relating to these Terms must be communicated to us using
        the contact information provided on this website.
      </div>
    </div>
  );
}

export default TermsConditions;
