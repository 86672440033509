import React, { useEffect } from 'react';
import './refundpolicy.scss';

function RefundPolicy() {
    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, []);
  return (
    <div className="refund-policy-container">
      <h1>Cancellation & Refund Policy</h1>
      <div className="last-updated">Last updated on 09-10-2024</div>
      <div className="policy-details">
        <p>SUVIDHA VISHWANATH KULKARNI believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy:</p>
        <ul>
          <li>Cancellations will be considered only if the request is made immediately after placing the order. However, the cancellation request may not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the process of shipping them.</li>
          <li>SUVIDHA VISHWANATH KULKARNI does not accept cancellation requests for perishable items like flowers, eatables, etc. However, refund/replacement can be made if the customer establishes that the quality of the product delivered is not good.</li>
          <li>In case of receipt of damaged or defective items, please report the same to our Customer Service team. The request will be entertained once the merchant has checked and determined the same at their own end. This should be reported within the same day of receipt of the products.</li>
          <li>If you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within the same day of receiving the product. The Customer Service Team will take an appropriate decision after reviewing your complaint.</li>
          <li>In case of complaints regarding products that come with a warranty from manufacturers, please refer the issue to them. In case of any refunds approved by SUVIDHA VISHWANATH KULKARNI, it will take 1-2 days for the refund to be processed to the customer.</li>
        </ul>
      </div>
      <div className="footer">
        For any further inquiries, feel free to contact our customer service.
      </div>
    </div>
  );
}

export default RefundPolicy;
