import React, { useState, useEffect, useContext } from 'react';
import './index.scss';
import { IoIosPlay } from "react-icons/io";
import { Link } from 'react-router-dom';
import Carousel from '../../../carousal/Carousel';
import Equipment from '../../CommonComponents/EquipmentCard/Equipment';
import CategoryNavBar from '../../CommonComponents/categoryNavBar';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { GetActiveSubscriptionEquipments } from '../../../service/equipmentService';
import { LoadingContext } from '../../../context/LoadingContext';

function Equipments() {
  const [equipmentsData, setEquipmentsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All');

  const { showLoader, hideLoader } = useContext(LoadingContext);

  useEffect(() => {
    fetchEquipmentCategories();
  }, []);

  useEffect(() => {
    console.log('Selected Category:', selectedCategory);
    filterEquipments();
  }, [selectedCategory, equipmentsData]);

  const fetchEquipmentCategories = async () => {
    setLoading(true);
    try {
      const response = await GetActiveSubscriptionEquipments({
        searchTerm: "",
        startRentprice: 0,
        endRentprice: 0,
        categoryId: '',
        insuranceAvailable: true,
      });

      if (Array.isArray(response)) {
        setEquipmentsData(response);
        setFilteredData(response);
      } else {
        console.error("Unexpected response structure:", response);
        setError(response.message || "Failed to fetch equipment data.");
      }
    } catch (error) {
      console.error("Error fetching equipment data:", error);
      setError(error.message || "An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  const filterEquipments = async () => {
    setLoading(true);
    try {
      let categoryId = '';

      if (selectedCategory !== 'All') {
        const selectedCategoryObject = equipmentsData.find(item =>
          item.categories && item.categories.some(category => category.name === selectedCategory)
        );
        if (selectedCategoryObject) {
          categoryId = selectedCategoryObject.categories[0]?.id || '';
        }
      }

      const response = await GetActiveSubscriptionEquipments({
        searchTerm: "",
        startRentprice: 0,
        endRentprice: 0,
        categoryId: categoryId,
        insuranceAvailable: true,
      });

      if (Array.isArray(response)) {
        setFilteredData(response);
      } else {
        console.error("Unexpected response structure:", response);
        setError(response.message || "Failed to fetch filtered equipment data.");
      }
    } catch (error) {
      console.error("Error fetching filtered equipment data:", error);
      setError(error.message || "An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='equipments-container'>
      <CategoryNavBar selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
      <div className='bar'>
        <p>Products / Equipments</p>
        <Link to="/equipments">
          <span className='hold'>
            <IoIosPlay />
            View All
          </span>
        </Link>
      </div>
      {loading ? (
        <div className='equipments'>
          {[...Array(5)].map((_, index) => (
            <div className="equipment-skeleton" key={index}>
              <Skeleton height={200} width={200} />
              <Skeleton width={100} />
              <Skeleton width={60} />
            </div>
          ))}
        </div>
      ) : error ? (
        <div className="error-message">Error: {error}</div>
      ) : (
        <div className='equipments'>
          <Carousel slidesToShow={4} slidesToScroll={3}>
            {filteredData?.map((equipment, index) => (
              <Equipment
                key={index}
                status={equipment.status}
                name={equipment.name}
                img={equipment.image1Url}
                rent={equipment.finalRentalValue}
                color='#FFF7E7'
                rating={3}
                equipment={equipment}
              />
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
}

export default Equipments;
