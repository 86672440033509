import { useContext } from 'react';
import './globalloader.scss';
import { LoadingContext } from '../../../context/LoadingContext';

const GlobalLoader = () => {
  const { isLoading } = useContext(LoadingContext);

  if (!isLoading) return null;

  return (
    <div className="global-loader">
      <div className="loader-spinner">
        <div className="spinner"></div> {/* Spinning loader */}
        <div className="loading-text">Loading...</div> {/* Static text */}
      </div>
    </div>
  );
};

export default GlobalLoader;
