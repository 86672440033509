import React, { useRef, useState } from 'react';
import { MdOutlineStarPurple500 } from "react-icons/md";
import './rating.scss';




const Rating = ({ totalStars = 5, onRatingChange }) => {
  const [rating, setRating] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [lockedRating, setLockedRating] = useState(false);
//   const [glowStars, setGlowStars] = useState(false);
  const starsRef = useRef(null);



  const handleMouseMove = (e) => {
    if (lockedRating || !isDragging) return;

    const rect = starsRef.current.getBoundingClientRect();
    const width = rect.width;
    const offsetX = e.clientX - rect.left;
    const newRating = Math.min(Math.max((offsetX / width) * totalStars, 0), totalStars);

    setRating(newRating);
    onRatingChange && onRatingChange(newRating);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    handleMouseMove(e);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setLockedRating(true);
    // setGlowStars(true);

    // setTimeout(() => setGlowStars(false), 1000);
  };

  const handleMouseLeave = () => {
    if (!isDragging) setIsDragging(false);
  };

  const handleStarClick = (i) => {
    onRatingChange && onRatingChange(i+1);
    setRating(i + 1);
    setLockedRating(false);
  };

  const getClipPath = (index) => {
    const portion = Math.max(0, Math.min(1, rating - index));
    return `polygon(0 0, ${portion * 100}% 0, ${portion * 100}% 100%, 0 100%)`;
  };

  return (
    <div className="draggable-rating-container" style={{ position: 'relative' }}>

      <div
        className="draggable-rating"
        ref={starsRef}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
      >
        {[...Array(totalStars)].map((_, i) => (
          <div
            key={i}
            className={`star-wrapper`}
            style={{
              position: 'relative',
              display: 'inline-block',
              width: '24px',
              height: '24px',
              cursor: 'pointer',
            }}
            onClick={() => handleStarClick(i)}
          >
            <MdOutlineStarPurple500 size={28}
              className="star-background"
              style={{ position: 'absolute', left: 0, top: 0, color: '#ddd' }}
            />
            <MdOutlineStarPurple500 size={28}
              className="star-fill"
              style={{
                clipPath: getClipPath(i),
                color: '#ffd700',
                position: 'absolute',
                left: 0,
                top: 0,
              }}
            />
          </div>
        ))}
      </div>
      <div className="rating-number">
        {rating.toFixed(1)} / {totalStars.toFixed(1)}
      </div>
    </div>
  );
};

export default Rating;
