import React from 'react';
import './index.scss'
import { Outlet } from 'react-router-dom';
import Sidebar from './sidebar/Sidebar';

function DashboardLayout() {
  return (
    <div className="dashboard-container">
      <Sidebar />
      <Outlet />
    </div>
  );
}

export default DashboardLayout;
