// DynamicCard.js

import React from 'react';
import './dynamiccard.scss';
import { Link, useNavigate } from 'react-router-dom';

const DynamicCard = ({ cards }) => {
  const navigate = useNavigate();

  const handleBuyNow = (plan) => {
    navigate('/dashboard/subscription-plan', { state: { plan } });
  };
  return (
    <div className='dynamiccard-container'>
      {cards.map((card, index) => (
        <div key={index} className={`dynamic-card ${card.bestseller ? 'bestseller' : ''}`}>
          <h1>{card.title.toUpperCase()}</h1>
          <div className='discount'>
            {/* <span className='discount-span'>SAVE {card.discount}</span>
            <p className='discount-price'>₹ {card.discountPrice}</p> */}
          </div>
          <div className='price'>₹ {card.price} <span>{card.priceUnit}</span></div>
          <button onClick={() => handleBuyNow(card)}>{card.buttonText}</button>
          <ul>
            {card.features.map((feature, idx) => (
              <li key={idx}>{feature}</li>
            ))}
          </ul>
          {card.bestseller ? (
                <div className='bestseller-label'>BEST SELLER</div>
          ) : ''}
        </div>
      ))}
    </div>
  );
};

export default DynamicCard;
