import React from 'react';
import './Checkbox.scss';

const Checkbox = ({ label, checked, onChange }) => {
  return (
    <div className='checkbox'>
      <label className='custom-checkbox'>
        <input type='checkbox' checked={checked} onChange={onChange} />
        <span className='checkmark'></span>
      </label>
      <p>{label}</p>
    </div>
  );
};

export default Checkbox;
