import React, { useState } from 'react';
import './ratingmodal.scss'; // Add styles for the modal
import Rating from '../Rating';

const RatingModal = ({ show, onClose, onSubmit, equipmentId, userId, userName, equipmentName }) => {
  const [starRating, setStarRating] = useState(0);
  const [comment, setComment] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [userRating, setUserRating] = useState(0);

  const maxCharCount = 250;

  const handleRatingChange = (newRating) => {
    console.log(starRating)
    setStarRating(newRating);
  };
  const handleStarClick = (rating) => {
    setStarRating(rating);
  };

  const handleCommentChange = (e) => {
    const value = e.target.value;
    if (value.length <= maxCharCount) {
      setComment(value);
      setCharCount(value.length);
    }
  };

  const handleSubmit = () => {
    console.log("star rating",starRating);
    console.log("comment",comment);
    if (starRating && comment.trim()) {
      const payload = {
        equipmentId,
        userId,
        userName,
        starRating,
        comment
      };
      onSubmit(payload);
    } else {
      alert("Please provide a rating and a comment.");
    }
  };

  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Rate {equipmentName}</h2>
        <Rating totalStars={5} onRatingChange={handleRatingChange} />
        <textarea
          value={comment}
          onChange={handleCommentChange}
          placeholder="Write your review (Max 250 characters)"
        />
        <div className="char-count">{charCount}/{maxCharCount}</div>
        <button className="submit-btn" onClick={handleSubmit}>Submit Equipment Rating</button>
        <button className="close-btn" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default RatingModal;
