import React from 'react'
import './master.scss'
import { Route, Routes } from 'react-router-dom'
import EquipmentCategory from '../equipment category/EquipmentCategory'
import EquipmentType from '../equipment type/EquipmentType'

function Master() {
  return (
    <div>
        <Routes>
            <Route path='/master-equipment-type' element={<EquipmentType/>} />
            <Route path='/master-equipment-category' element={<EquipmentCategory/>} />
        </Routes>
    </div>
  )
}

export default Master