import React, { useEffect, useRef, useState, useCallback } from 'react';
import './dropdown.scss';
import { IoCaretDownSharp, IoCaretUpSharp } from 'react-icons/io5';

function Dropdown({ label, options = [], onChange, id, value = [], multiple = false, error = false, reset, readOnly = false, disable = false }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const normalizedValue = Array.isArray(value) ? value : [value];
    setSelectedOptions(options.filter(opt => normalizedValue.includes(opt.id)));
  }, [value, options]);

  useEffect(() => {
    if (reset) {
      setSelectedOptions([]);
    }
  }, [reset]);

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }, []);

  const handleSelect = useCallback((option) => {
    if (multiple) {
      const updatedOptions = selectedOptions.some(opt => opt.id === option.id)
        ? selectedOptions.filter(opt => opt.id !== option.id)
        : [...selectedOptions, option];

      setSelectedOptions(updatedOptions);
      onChange(updatedOptions.map(opt => opt.id));
    } else {
      setSelectedOptions([option]);
      onChange(option.id);
      setIsOpen(false);
    }
  }, [multiple, onChange, selectedOptions]);

  const handleToggleDropdown = useCallback(() => {
    if (!disable) {
      setIsOpen(prev => !prev);
    }
  }, [disable]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  return (
    <div className={`add-equipment-dropdown ${disable ? 'disabled' : ''}`} ref={dropdownRef}>
      <label htmlFor={id} className={`dropdown ${error ? 'error' : ''}`}>
        <input
          type="text"
          id={id}
          value={selectedOptions.map(opt => opt.value).join(', ')}
          readOnly={readOnly || disable}
          placeholder={label}
          onClick={handleToggleDropdown}
          disabled={disable}
        />
        {isOpen ? <IoCaretUpSharp /> : <IoCaretDownSharp />}
      </label>
      {isOpen && (
        <ul className="dropdown-options">
          {options.map((option, index) => (
            <li
              key={index}
              onClick={() => handleSelect(option)}
              className={selectedOptions.some(opt => opt.id === option.id) ? 'selected' : ''}
            >
              {multiple ? (
                <input
                  type="checkbox"
                  checked={selectedOptions.some(opt => opt.id === option.id)}
                  onChange={() => handleSelect(option)}
                />
              ) : (
                <div className="dot" />
              )}
              {option.value}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Dropdown;
