import React, { useState, useEffect } from 'react';
import './uploadimage.scss';
import { TfiUpload } from "react-icons/tfi";
import { CgClose } from "react-icons/cg";

function UploadImage({ onImageSelected, index, hasImage, showError, initialImage, readOnly = false }) {
  const [image, setImage] = useState(initialImage || null);
  const [hasSelectedImage, setHasSelectedImage] = useState(!!initialImage);

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      setImage(file);
      onImageSelected(index, file);
      setHasSelectedImage(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleInputChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setImage(file);
      onImageSelected(index, file);
      setHasSelectedImage(true);
    }
  };

  const handleRemoveImage = () => {
    setImage(null);
    onImageSelected(index, null);
    setHasSelectedImage(false);
  };

  useEffect(() => {
    if (!hasImage) {
      setImage(null);
      setHasSelectedImage(false);
    } else if (hasImage && image !== null) {
      setHasSelectedImage(true);
    }
  }, [hasImage, image]);

  useEffect(() => {
    if (initialImage) {
      setImage(initialImage);
      setHasSelectedImage(true);
    }
  }, [initialImage]);

  return (
    <div className={`img-container ${showError && !hasSelectedImage ? 'error' : ''}`}>
      {!image  && (
        <>
          <label htmlFor={`images-${index}`} className='img-placeholder' onDrop={handleDrop} onDragOver={handleDragOver}>
            <TfiUpload size={12} />
            Drag Your File<br />
            to Upload
            <p className='max-size'>(max 1 mb)</p>

          </label>
        </>
      )}

      <input
        type='file'
        id={`images-${index}`}
        accept="image/*"
        style={{ visibility: 'hidden', position: 'absolute' }}
        onChange={handleInputChange}
        disabled={readOnly}
      />

      {image && (
        <div className='image-preview'>
          <img src={typeof image === 'string' ? image : URL.createObjectURL(image)} alt='Equipment Preview' />
          {readOnly ? '' : <CgClose className='delete-icon' color='white' size={18} onClick={handleRemoveImage} />}

        </div>
      )}
    </div>
  );
}

export default UploadImage;
