import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './carousel.scss';

const Carousel = ({ children, slidesToShow = 4, slidesToScroll = 3 }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    infinite: React.Children.count(children) > 1,
    arrows: true,
    speed: 800,
    autoplaySpeed: 5500,
    cssEase: "linear",
    afterChange: (slideIndex) => setCurrentSlide(slideIndex),
    responsive: [
      {
        breakpoint: 1244,
        settings: {
          slidesToShow: slidesToShow > 3 ? 4 : slidesToShow,
          slidesToScroll: slidesToScroll > 2 ? 2 : slidesToScroll,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slidesToShow > 2 ? 3 : slidesToShow,
          slidesToScroll: slidesToScroll > 1 ? 2 : slidesToScroll,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: slidesToShow > 1 ? 2 : slidesToShow,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className='carousel-container'>
      <Slider {...settings}>
        {children}
      </Slider>
    </div>
  );
};

export default Carousel;
