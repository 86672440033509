import React from 'react';
import { motion } from 'framer-motion';
import './tab.scss';

const Tab = ({ label, onClick, isActive }) => {
  return (
    <button className={`tab-button ${isActive ? 'active' : ''}`} onClick={onClick}>
      {label}
      {isActive && (
        <motion.div
          className="tab-highlight"
          layoutId="tab-highlight"
          initial={false}
          animate
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        />
      )}
    </button>
  );
};

export default Tab;
