import React from 'react';
import './index.scss';
import Header from './header/Header';
import MainPage from './mainpage/MainPage';
import Equipments from './equipments'
import Location from './location/Location';
import DownloadApp from '../download-app/DownloadApp';
import AboutUs from '../about-us/AboutUs';
import Testimony from './testimony/Testimony';
import Footer from '../footer/Footer';

function Home() {
  return (
    <div className='home-container'>

      
        <MainPage/>
        <Equipments/>
        <Location/>
        <DownloadApp/>
        <AboutUs/>
        <Testimony/>
        {/* <Footer/> */}
    </div>
  )
}

export default Home