import React from 'react';

const TextArea = ({ label, id, value, onChange, maxLength, count, readOnly = false }) => {
  return (
    <>
      <label htmlFor={id} className='input-label'>
        {label} <span>({count}/{maxLength})</span>
      </label>
      <textarea
        id={id}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        readOnly={readOnly}
      />
    </>
  );
};

export default TextArea;
