import React from 'react'
import './aboutus.scss'
import customerService from './customer-service.png';
import badge from './premium-badge.png';
import rupee from './rupee-symbol.png'
import shopping from './shopping-cart.png'


function AboutUs() {
  return (
    <div className='about-container'>

        <div className='top'>
                <h2>Why Choose Us?</h2>
                <p>Ask any of our loyal customers, and you will hear the following</p>
        </div>
        <div className='bottom'>
            <div className='perk first'>
                <div className='perk-img'>

                <img src={shopping} alt='perk-icon'/>
                </div>
                <div className='perk-desc'>
                    <h3 className='title'>One Stop Rental Shop</h3>
                    <p>Get every piece of essential equipment for your project.</p>
                </div>
            </div>
            <div className='perk'>
                <div className='perk-img'>
                    
                        <img src={rupee} alt='perk-icon'/>
                </div>
                <div className='perk-desc'>
                    <h3 className='title'>Value For Money</h3>
                    <p>Top-quality equipment at the most competitive prices in the market.</p>
                </div>
            </div>
            <div className='perk first'>
            <div className='perk-img'>
                    
                <img src={badge} alt='perk-icon'/>
                    </div>
                <div className='perk-desc'>
                    <h3 className='title'>Assured Quality Equipment</h3>
                    <p>Machines thoroughly inspected by highly qualified technicians to ensure top performance.</p>
                </div>
            </div>
            <div className='perk'>
            <div className='perk-img'>
                    
                <img src={customerService} alt='perk-icon'/>
                    </div>
                <div className='perk-desc'>
                    <h3 className='title'>Great Customer Service</h3>
                    <p>We're Quick. Access our 24/7 helpline for seamless, round-the-clock communications.</p>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default AboutUs