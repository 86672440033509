import React, { useEffect, useState } from 'react';
import './cashfreegateway.scss';
import axios from 'axios';
import { load } from '@cashfreepayments/cashfree-js';
import { toast } from 'sonner';

function CashfreeGateway() {
  const [orderId, setOrderId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  let cashfree;

  const initializeSDK = async () => {
    try {
      cashfree = await load({ mode: 'sandbox' });
    } catch (err) {
      console.error('Failed to load Cashfree SDK:', err);
      toast.error('Error initializing payment gateway.');
    }
  };

    initializeSDK();


  const getSessionId = async () => {
    setLoading(true);
    try {
      const res = await axios.get('http://localhost:8000/payment');
      console.log("this is response from payment",res.data)
      if (res.data && res.data.payment_session_id) {
        console.log(res.data)
        setOrderId(res.data.order_id);
        return res.data.payment_session_id;
      }
      throw new Error('Invalid payment session response');
    } catch (err) {
      console.error('Error getting payment session ID:', err);
      setError('Failed to initiate payment. Please try again.');
      toast.error('Failed to get payment session.');
    } finally {
      setLoading(false);
    }
  };

  console.log("this is the order id",orderId)

  const verifyPayment = async () => {
    setLoading(true);
    try {
      const res = await axios.post('http://localhost:8000/verify', { orderId: orderId });
      if (res.data) {
        toast.success('Payment successful!');
      } else {
        throw new Error('Payment verification failed');
      }
    } catch (err) {
      console.error('Error verifying payment:', err);
      toast.error('Payment verification failed.');
    } finally {
      setLoading(false);
    }
  };


  const handlePayment = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    try {
      const sessionId = await getSessionId();
      if (!sessionId) return;

      const checkoutOptions = {
        paymentSessionId: sessionId,
        redirectTarget: '_parent',
        returnUrl: "http://localhost:3000"
      };

      cashfree.checkout(checkoutOptions).then((res) => {
        console.log('Payment initiated:', res);
        verifyPayment();
      }).catch((err) => {
        console.error('Error initiating payment:', err);
        toast.error('Payment initiation failed.');
      });

    } catch (err) {
      console.error('Payment handling error:', err);
      toast.error('Failed to process payment.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="cashfree-container">
      <h1 className="cashfree-heading">Cashfree Payment Gateway</h1>
      <p className="cashfree-description">
        Please proceed with your payment securely via Cashfree.
      </p>

      {error && <div className="error-message">{error}</div>}

      <button
        className="pay-now-btn"
        onClick={handlePayment}
        disabled={loading}
      >
        {loading ? 'Processing...' : 'Pay Now'}
      </button>
    </div>
  );
}

export default CashfreeGateway;