import React, { useState } from 'react'
import './admincustomer.scss'
import Button from '../../../dashboard/common-components/buttons/Button';
import InputField from '../../../dashboard/common-components/inputs/InputField';
import UploadImage from '../../CommonComponents/upload-image/UploadImage';

function AdminCustomer() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        mobileNumber: '',
        panNumber: '',
        organizationName: '',
        cinNumber: '',
        gstNumber: '',
        address: '',
        city: '',
        state: '',
        pincode: '',
        email: '',
        altMobileNumber: '',
        image: null,
        organizationDetailsAvailable: false,
      });

      const handleImageSelected = (image) => {
        setFormData({ ...formData, image });
      };

      const handleChange = (e) => {
        const { id, value, type, checked } = e.target;
        setFormData({
          ...formData,
          [id]: type === 'checkbox' ? checked : value,
        });
      };

      const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
      };

      return (
        <div className='admin-customer-container'>
          <form onSubmit={handleSubmit}>
            <h3>CUSTOMER</h3>
            <div className='info-field'>
              <UploadImage onImageSelected={handleImageSelected} />
              <div className='info-container'>
                <div className='split-input'>
                  <InputField
                    label='First Name'
                    id='firstName'
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                  <InputField
                    label='Mobile Number'
                    id='mobileNumber'
                    value={formData.mobileNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className='split-input'>
                  <InputField
                    label='Last Name'
                    id='lastName'
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                  <InputField
                    label='PAN Number'
                    id='panNumber'
                    value={formData.panNumber}
                    onChange={handleChange}
                  />
                </div>
                {/* <div className='check-bar'>
                  <Checkbox
                    label='Tick if Organization Details are Available.'
                    id='organizationDetailsAvailable'
                    checked={formData.organizationDetailsAvailable}
                    onChange={handleChange}
                  />
                  <hr />
                </div> */}
              </div>
            </div>
            {formData.organizationDetailsAvailable && (
              <>
                <InputField
                  className='half'
                  label='Organization Name'
                  id='organizationName'
                  value={formData.organizationName}
                  onChange={handleChange}
                />
                <InputField
                  className='half'
                  label='CIN Number'
                  id='cinNumber'
                  value={formData.cinNumber}
                  onChange={handleChange}
                />
                <InputField
                  className='half'
                  label='GST Number'
                  id='gstNumber'
                  value={formData.gstNumber}
                  onChange={handleChange}
                />
              </>
            )}
            {/* <hr /> */}
            <InputField
              label='Address'
              id='address'
              value={formData.address}
              onChange={handleChange}
            />
            <div className='input-field'>
              <InputField
                label='City'
                id='city'
                value={formData.city}
                onChange={handleChange}
              />
              <InputField
                label='State'
                id='state'
                value={formData.state}
                onChange={handleChange}
              />
            </div>
            <div className='input-field'>
              <InputField
                label='Pincode'
                id='pincode'
                value={formData.pincode}
                onChange={handleChange}
              />
              <InputField
                label='Email'
                id='email'
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className='input-field'>
              <InputField
                label='Mobile No.'
                id='mobileNumber'
                value={formData.mobileNumber}
                onChange={handleChange}
              />
              <InputField
                label='Alt. Mobile No.'
                id='altMobileNumber'
                value={formData.altMobileNumber}
                onChange={handleChange}
              />
            </div>
            <div className='btns-container'>
              <Button type='submit' className='save-btn' label='Save' />
              <Button type='button' className='cancel-btn' label='Cancel' onClick={() => setFormData({ ...formData, firstName: '' })} />
            </div>
          </form>
        </div>
      );
    }
export default AdminCustomer