import React, { useContext, useState, useEffect } from 'react';
import './sidebar.scss';
import { MdSpaceDashboard } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { BsFillGearFill } from "react-icons/bs";
import { RiMoneyRupeeCircleFill } from "react-icons/ri";
import { FaCrown } from "react-icons/fa";
import { TiSpanner } from "react-icons/ti";
import { RiDoorOpenFill } from "react-icons/ri";
import { FaGear } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";
import { TbFileDatabase } from "react-icons/tb";
import { GoChevronRight } from "react-icons/go";
import { PiFilesDuotone } from "react-icons/pi";
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import UserContext from '../../context/userContext';
import { BiTimeFive } from "react-icons/bi";

function Sidebar() {
    const navigate = useNavigate();
    const location = useLocation();
    const context = useContext(UserContext);
    const { user, setUser, showSidebar, handleShowSidebar } = context;
    const [openMaster, setOpenMaster] = useState(false);


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768 && showSidebar) {
                handleShowSidebar();
            }
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [showSidebar, handleShowSidebar]);

    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        setUser(null);
        navigate('/');
    };

    const toggleMaster = () => {
        setOpenMaster(prevState => !prevState);
    };

    useEffect(() => {
        const isMasterRoute = location.pathname.includes('/dashboard/master/') ||
                              location.pathname.includes('/dashboard/master/');
        setOpenMaster(isMasterRoute);
    }, [location.pathname]);


    return (
        <div className={`sidebar-container ${showSidebar ? 'expanded' : 'collapsed'}`}>
            <ul>
                {user?.role?.toLowerCase() === 'admin' && (
                <li>
                    <NavLink to='/dashboard' end className={({ isActive }) => (isActive ? 'active-link' : '')}>
                        <MdSpaceDashboard size={16} /> Dashboard
                    </NavLink>
                </li>

                )}

                <li>
                    <NavLink to='/dashboard/profile' className={({ isActive }) => (isActive ? 'active-link' : '')}>
                        <FaUser size={16} /> Profile
                    </NavLink>
                </li>

                {user?.role?.toLowerCase() === 'admin' && (
                    <li>
                        <NavLink to='/dashboard/customer' className={({ isActive }) => (isActive ? 'active-link' : '')}>
                            <FaUsers size={16} /> Customers
                        </NavLink>
                    </li>
                )}

                {user?.role?.toLowerCase() === 'admin' && (
                    <li>
                        <NavLink to='/dashboard/equipments-list' className={({ isActive }) => (isActive ? 'active-link' : '')}>
                            <FaGear size={16} /> Equipments List
                        </NavLink>
                    </li>
                )}

                {(user?.role?.toLowerCase() === 'supplier' || user?.role?.toLowerCase() === 'user')  && (
                    <li>
                        <NavLink to='/dashboard/equipments-list' className={({ isActive }) => (isActive ? 'active-link' : '')}>
                            <BsFillGearFill size={16} /> Equipments List
                        </NavLink>
                    </li>
                )}

                {(user?.role?.toLowerCase() === 'supplier' || user?.role?.toLowerCase() === 'user')  && (
                    <li>
                        <NavLink to='/dashboard/recently-visited' className={({ isActive }) => (isActive ? 'active-link' : '')}>
                            <BiTimeFive size={16} /> Recently Visited
                        </NavLink>
                    </li>
                )}

                {user?.role?.toLowerCase() === 'admin' && (
                    <li>
                        <NavLink to='/dashboard/subscription-admin' className={({ isActive }) => (isActive ? 'active-link' : '')}>
                            <FaCrown size={16} /> Subscriptions
                        </NavLink>
                    </li>
                )}

                {(user?.role?.toLowerCase() === 'supplier' || user?.role?.toLowerCase() === 'user')  && (
                    <li>
                        <NavLink to='/dashboard/subscription-admin' className={({ isActive }) => (isActive ? 'active-link' : '')}>
                            <FaCrown size={16} /> Subscriptions
                        </NavLink>
                    </li>
                )}

                {/* {(user?.role?.toLowerCase() === 'supplier' || user?.role?.toLowerCase() === 'user')  && (
                    <li>
                        <NavLink to='/dashboard/payments' className={({ isActive }) => (isActive ? 'active-link' : '')}>
                            <RiMoneyRupeeCircleFill size={16} /> Payments
                        </NavLink>
                    </li>
                )} */}

                {user?.role?.toLowerCase() === 'admin' && (
                    <li>
                        <NavLink to='/dashboard/admin-payments' className={({ isActive }) => (isActive ? 'active-link' : '')}>
                            <RiMoneyRupeeCircleFill size={16} /> Payments
                        </NavLink>
                    </li>
                )}

                {user?.role?.toLowerCase() === 'admin' && (
                    <li>
                        <div className='dropdown'>
                            <div className='top' onClick={toggleMaster}>
                                <span><TbFileDatabase size={16} /> MASTER</span>
                                <GoChevronRight className={`chevron-icon ${openMaster ? 'rotate-icon' : ''}`}/>
                            </div>
                            {openMaster && (
                                <ul className='dropdown-list'>
                                    <NavLink to='/dashboard/master/master-equipment-type' className={({ isActive }) => (isActive ? 'active-link' : '')}>
                                        <li>Equipment Types</li>
                                    </NavLink>
                                    <NavLink to='/dashboard/master/master-equipment-category' className={({ isActive }) => (isActive ? 'active-link' : '')}>
                                        <li>Equipment Categories</li>
                                    </NavLink>
                                </ul>
                            )}
                        </div>
                    </li>
                )}

                {user?.role?.toLowerCase() === 'admin' && (
                    <li>
                        <NavLink to='/dashboard/reports' className={({ isActive }) => (isActive ? 'active-link' : '')}>
                            <PiFilesDuotone size={16} /> REPORTS
                        </NavLink>
                    </li>
                )}

{user?.role?.toLowerCase() === 'admin' && (
                <li>
                    <NavLink to='/dashboard/settings' className={({ isActive }) => (isActive ? 'active-link' : '')}>
                        <TiSpanner size={16} /> SETTINGS
                    </NavLink>
                </li>
)}
                <li onClick={handleLogout}>
                    <RiDoorOpenFill size={16}  /> LOG OUT
                </li>
            </ul>
        </div>
    );
}

export default Sidebar;
