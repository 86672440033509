import React, { useContext, useState } from 'react';
import './login.scss';
import { IoIosClose } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../../Constants/APIConstants';
import { toast } from 'sonner';
import UserContext from '../../../context/userContext';

const CaptchaKey = process.env.REACT_APP_RECAPTCHA;

function Login({onClose, showSignup}) {
    const navigate = useNavigate();
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);
    const { login } = useContext(UserContext);

    function onChange(value) {
        console.log("Captcha value:", value);
        setCaptchaValue(value);
    }

    const goback = () => {
        navigate('/');
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await axios.post(`${API_URL}/Login/Login`, {
                userName: userId,
                password: password
            });

            if (response.status === 200) {
                console.log('Login successful', response.data);
                const { access_token } = response.data.data;
                login(access_token);
                localStorage.setItem('accessToken', access_token);
                toast.success('Login successful!', {
                    variant: 'success',
                    closeButton: true,
                    duration: 2500
                });
                navigate('/');
                onClose();
            } else {
                console.error('Login failed', response.data);
                toast.error('Login failed: ' + response.data.message, {
                    variant: 'error',
                    closeButton: true,
                    duration: 2500
                });
            }
        } catch (error) {
            console.error('Login error', error);
            toast.error('Login Failed: ' + (error.response?.data?.message || error.message), {
                variant: 'error',
                closeButton: true,
                duration: 1500
            });
        } finally {
            setIsLoading(false);
        }
    }

    const handleUserIdChange = (e) => {
        const value = e.target.value;
        const regex = /^[0-9\b]+$/;
        if (value === '' || (regex.test(value) && value.length <= 10)) {
            setUserId(value);
        }
    };

    return (
        <div className='login-container'>
            <div className='login-box'>
                <div className='cancel'>
                    <IoIosClose size={30} color='#252525' onClick={onClose} />
                </div>
                <div className='advertisement-box'>
                    advertisement banner to be shown here
                </div>
                <form onSubmit={handleLogin}>
                    <div className='form-head' >
                        <h3>Log in</h3>
                        <p style={{cursor: "pointer"}}>Do not have an account? <span onClick={showSignup}>Sign Up</span></p>
                    </div>

                    <div className='input-box'>
                        <label><span className='label-text'>Enter Mobile number</span></label>
                        <input
                            type='text'
                            placeholder='Enter your mobile number'
                            value={userId}
                            onChange={handleUserIdChange}
                        />
                    </div>
                    <div className='input-box'>
                        <label><span className='label-text'>Password</span> <span className='forgot-text'>Forgot Password</span></label>
                        <input
                            type='password'
                            placeholder='Enter your password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    {/* <div className='captcha-box'>
                        <ReCAPTCHA
                            sitekey={CaptchaKey}
                            onChange={onChange}
                        />
                    </div> */}
                    <button disabled={isLoading} type='submit'>{ isLoading ? "Logging in..." : "Log in" }</button>
                </form>
            </div>
        </div>
    );
}

export default Login;
