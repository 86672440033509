import React, { useState, useRef, useEffect } from 'react';
import './self.scss';
import { signUpUser } from '../../../../service/loginService';
import axios from 'axios';
import { API_URL } from '../../../../Constants/APIConstants';
import { toast } from 'sonner';
import { IoCheckmarkCircleOutline, IoCloseCircleOutline, IoEyeOutline } from "react-icons/io5";
import { GoEyeClosed, GoXCircle } from "react-icons/go";

function Self({ showLogin }) {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    terms: false,
    updates: false,
  });

  const [otpFor, setOtpFor] = useState(null);
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [otpData, setOtpData] = useState({ id: '', securityStamp: '' });
  const otpInputRefs = useRef([]);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

  useEffect(() => {
    const shouldDisable =
      !isOtpVerified ||
      !formData.terms ||
      !formData.firstName.trim() ||
      !formData.lastName.trim() ||
      formData.phoneNumber === '' ||
      formData.email === '' ||
      formData.password === '';
    setIsButtonDisabled(shouldDisable);
  }, [isOtpVerified, formData]);

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (id === 'phoneNumber' && value.length > 10) return;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: type === 'checkbox' ? checked : value,
    }));

    if (id === 'phoneNumber') {
      const numericValue = value.replace(/\D/g, '');
      if (numericValue.length <= 10) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [id]: numericValue,
        }));
        setIsOtpVerified(false);

        // Reset OTP if phone number changes
        if (otpFor) {
          setOtp(Array(6).fill(''));
          setOtpFor(null);
        }

        setIsPhoneNumberValid(numericValue.length === 10);
      }
      return;
    }

    if (id === 'email') {
      if (emailRegex.test(value)) {
        setIsEmailValid(true);
      } else {
        setIsEmailValid(false);
      }
    }
  };


  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (isNaN(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value && index < 5) {
      otpInputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      if (index > 0) {
        otpInputRefs.current[index - 1].focus();
      }
    }
  };

  const handleVerifyClick = async (field) => {
    if (field === 'phone') {
      try {
        const response = await axios.post(API_URL + '/Verification/GenerateOtpForPhonenumberVerification', {
          phoneNumber: formData.phoneNumber,
        });
        setOtpFor(field);
        setIsOtpVerified(false);
        setOtpData({
          id: response.data.id,
          securityStamp: response.data.securityStamp,
        });
        toast.success('OTP has been sent to your phone.');
      } catch (error) {
        console.error('Error generating OTP:', error);
        toast.error('Failed to send OTP. Please try again.');
      }
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const otpValue = otp.join('');
    try {
      const response = await axios.post(API_URL + '/Verification/VerifyOtp', {
        id: otpData.id,
        otp: otpValue,
        securityStamp: otpData.securityStamp,
      });
      if (response.status === 200) {
        setIsOtpVerified(true);
        toast.success('OTP Verified Successfully!');
        setOtpFor(null);
      } else {
        toast.error('OTP Verification failed. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      toast.error('OTP Verification failed. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.firstName.trim() || !formData.lastName.trim()) {
      toast.error('First name and Last name are required.');
      return;
    }

    if (!formData.terms) {
      toast.error('You must agree to the terms and conditions to sign up.');
      return;
    }

    if (!passwordRegex.test(formData.password)) {
      toast.error(
        'Password must contain at least one symbol, one capital letter, one number, and be at least 8 characters long.'
      );
      return;
    }

    const payload = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      password: formData.password,
    };

    try {
      const response = await signUpUser(payload);
      if (response.message === 'User Created Successfully!') {
        toast.success('User created successfully. Please login to continue.');
        showLogin();
      } else {
        toast.error('Something went wrong. Please try again.');
      }
    } catch (error) {
      console.error('Error in registering new user:', error);
      // const errorMessage = error.response?.data?.message || 'Failed to register user. Please try again.';
      toast.error(error.response.data);
    }
  };

  const ValidationList = () => {
    const validations = [
      { id: 'firstName', label: 'First Name is required', isValid: !!formData.firstName.trim() },
      { id: 'lastName', label: 'Last Name is required', isValid: !!formData.lastName.trim() },
      { id: 'email', label: 'Email is valid', isValid: isEmailValid },
      { id: 'phoneNumberVerified', label: 'Mobile Number is verified', isValid: isOtpVerified },
      {
        id: 'password',
        label: 'Password is valid (8 chars, 1 symbol, 1 capital, 1 no.)',
        isValid: passwordRegex.test(formData.password),
      },
    ];

    return (
      <div className='validation-list'>
        {validations.map((validation) => (
          <div key={validation.id} className={`validation-item ${validation.isValid ? 'valid' : 'invalid'}`}>
            {validation.isValid ? (
              <IoCheckmarkCircleOutline color='green' size={15} />
            ) : (
              <GoXCircle color='red' size={15} />
            )}
            <span>{validation.label}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className='self-container'>
      <form>
        <div className='split-input'>
          <div className='input-field'>
            <input
              className='equipment-input'
              type='text'
              id='firstName'
              placeholder='First Name'
              value={formData.firstName}
              onChange={handleChange}
            />
          </div>
          <div className='input-field'>
            <input
              className='equipment-input'
              type='text'
              id='lastName'
              placeholder='Last Name'
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className='input-container'>
          <div className='input-field'>
          <input
              className={`equipment-input ${!isEmailValid ? 'invalid' : ''}`}
              type='email'
              id='email'
              placeholder='Email Id'
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className='input-field'>
            <input
              className='equipment-input'
              type='text'
              id='phoneNumber'
              placeholder='Mobile Number'
              value={formData.phoneNumber}
              onChange={handleChange}
            />
            {isPhoneNumberValid && formData.phoneNumber && (
              <button
                type='button'
                className={`verify-btn ${isOtpVerified ? 'verified' : ''}`}
                onClick={() => handleVerifyClick('phone')}
                disabled={isOtpVerified}
                style={{
                  color: isOtpVerified ? 'green' : '#3787e2',
                }}
              >
                {isOtpVerified ? 'Verified' : 'Verify'}
              </button>
            )}
          </div>
          <div className='input-field'>
            <input
              className='equipment-input'
              type={showPassword ? 'text' : 'password'}
              id='password'
              placeholder='Enter Password'
              value={formData.password}
              onChange={handleChange}
            />
            <button
              type='button'
              className='show-password-btn'
              onClick={() => setShowPassword((prev) => !prev)}
            >
              {showPassword ? <GoEyeClosed size={18}/> : <IoEyeOutline size={18}/> }
            </button>
          </div>
        </div>

        {otpFor && (
          <div className='otp-container'>
            <p>Enter OTP for Phone Number:</p>
            <form onSubmit={handleOtpSubmit} className='otp-form'>
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type='text'
                  className='otp-input'
                  maxLength='1'
                  value={digit}
                  onChange={(e) => handleOtpChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  ref={(el) => (otpInputRefs.current[index] = el)}
                />
              ))}
              <button type='submit' onClick={handleOtpSubmit} className='verify-btn'>
                Verify OTP
              </button>
            </form>
          </div>
        )}

        <div className='checkboxes'>
          <div className='box'>
            <input
              type='checkbox'
              id='terms'
              checked={formData.terms}
              onChange={handleChange}
            />
            <label for="terms">
            I agree to the
            <a href="/terms-and-conditions"  class="terms-link"  target="_blank"><b> Terms & Conditions</b></a>
          </label>
          <br />
          </div>
          <div className='box'>
            <input
              type='checkbox'
              id='updates'
              checked={formData.updates}
              onChange={handleChange}
            />
            <label htmlFor='updates'>
              I would like to be informed about the latest updates from
              MachinesOnRent India.
            </label>
          </div>
        </div>

        <div className='auth-btn'>
          <button type='submit' onClick={handleSubmit} disabled={isButtonDisabled}>
            Sign Up
          </button>
          <p>
            Have an account? <b onClick={showLogin}>Login Now</b>
          </p>
        </div>

        <ValidationList />
      </form>
    </div>
  );
}

export default Self;
