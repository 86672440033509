export const paymentColumns = [
    {Header: 'Id', accessor: 'id'},
    {Header: 'Date', accessor: 'paymentDate'},
    {Header: 'Amount', accessor: 'amount', format: 'currency'},
    {Header: 'Mode', accessor: 'mode'},
  ];


  export const admin_paymentColumns = [
    {Header: 'Cust. Name', accessor: 'userFullname'},
    {Header: 'Phonenumber', accessor: 'userPhonenumber'},
    {
      Header: 'Payment Date',
      accessor: 'paymentCompletionTime',
      Cell: ({ value }) => {
        return new Intl.DateTimeFormat('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        }).format(new Date(value));
      },
    },
    {
      Header: 'Amount',
      accessor: 'orderAmount',
    },
    {
      Header: 'Payment Type',
      accessor: 'paymentGroup',
    },
    {
      Header: 'Payment Status',
      accessor: 'paymentStatus',
      Cell: ({ value }) => {
        let color = '';

        switch (value) {
          case 'SUCCESS':
            color = 'green';
            break;
          case 'Pending':
            color = 'orange';
            break;
          case 'Failed':
            color = 'red';
            break;
          default:
            color = 'black';
        }

        return (
          <span style={{ color: color, fontWeight: 'bold' }}>
            {value}
          </span>
        );
      },
    },
  ];
